import React, {useEffect} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Button from "./Button";
import reactHtmlParser from "react-html-parser";


const Counter = ({ title, src, bg, data, desc, color}) => {
    return (
        <>
            <StyledDetail bg={bg} color={color}>
                <Container className={'pb-120 pt-120'}>
                    <Row>
                        <Col md={6} className={'counter-left pr-0'}>
                            <div className="content">
                                <div><h5>{reactHtmlParser(data?.[0]?.data?.subtitle ? data?.[0]?.data?.subtitle : title )}</h5></div>

                            </div>
                        </Col>
                        <Col className={'counter-right'} md={6}>
                            <div className="content">
                                <p>
                                    {reactHtmlParser(data?.[0]?.data?.description ? data?.[0]?.data?.description : desc)}
                                </p>
                                {
                                    src ?
                                        <Button text={'Download Brochure'} background={'transparent'} color={'#1C1C1C'}
                                                hoverBackground={'#535353'} marginTop={40} src={src}/>
                                        : ''
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </StyledDetail>
        </>

    );
};

const StyledDetail = styled.section`
    background-color: ${p => p.bg || ''};
    position: relative;

    .counter-left {
        .content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            margin-right: 100px;

            h5 {
                color: ${p => p.color || '#1C1C1C'};
                width: 80%;
            }

            .dc-btn {
                align-self: flex-end; /* Align the button to the bottom right */
                margin-top: auto;
            }
        }
    }

    .counter-right {
        .content {
            p {
                font-size: 18px;
                line-height: 24px;
                font-weight: 400;
                color: ${p => p.color || '#1C1C1C'};
            }
            //.dc-btn{
            //  display: none;
            //}
        }
    }

    @media (max-width: 767px) {
        .counter-left {
            .content {
                h5 {
                    font-size: 30px !important;
                    line-height: 38px !important;
                }
                .dc-btn{
                    display: none;
                }
            }
        }

        .counter-right {
            .content {
                p {
                    font-size: 18px;
                    line-height: 24px;
                    margin-top: 40px;
                    margin-bottom: 40px;
                }
                .dc-btn{
                    display: unset;
                }
            }
        }
    }
`;

export default React.memo(Counter);
