import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Modal, Row} from "react-bootstrap";
import {Img} from '../Img';
import Title from "../Title";
import ReactHtmlParser from "react-html-parser";
import reactHtmlParser from "react-html-parser";

const BodV2 = ({title, bgColor, background_color, content_color, auditCommitteeList, data}) => {

    const [show, setShow] = useState(false);
    const [BgHeight, setBgHeight] = useState();
    const [item, setItem] = useState('');


    const ref = useRef();

    useEffect(() => {
        setBgHeight(ref?.current?.offsetHeight + 149);
    }, [bgColor, BgHeight]);


    return (
        <StyledDirectorList BgHeight={BgHeight} content_color={content_color} background_color={background_color}
                            bgColor={bgColor} className='directors_list_section pt-150 pb-150'>
            <Container>
                <Row>
                    <Col md={12}>
                        <Title margin="0 0 40px" text={ReactHtmlParser('Senior Management')}/>
                    </Col>
                </Row>
                <Row>
                    {
                        data && data?.length > 0 &&
                        data?.map((e,i)=>{
                            return(
                                <Col md={4} className='fade-up' key={i}>
                                    <div  className="directors_single">
                                        <div className="directors_single__img_wrapper">
                                            <Img src={e?.images?.[0]?.full_path} alt=""/>
                                        </div>
                                        <div ref={ref} className="directors_single__content">
                                            <h3>{reactHtmlParser(e?.data?.title)}</h3>
                                            <p>{reactHtmlParser(e?.data?.subtitle)}</p>
                                        </div>
                                    </div>
                                </Col>
                            );
                        })
                    }
                </Row>
            </Container>
        </StyledDirectorList>

    );
};

const StyledDirectorList = styled.section`
  background: ${props => props.background_color ? props.background_color : '#FFFFFF'};
  position: relative;
  @media (max-width: 767px) {
    .container {
      overflow: unset;
    }
  }

  &:after {
    background: ${props => props.bgColor ? props.bgColor : '#E9E9E9'};
    //content: "";
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    position: absolute;
    height: ${props => props.BgHeight}px;
    @media (max-width: 767px) {
      height: 100%;
      top: 0;
    }
  }

  .container {
    position: relative;
    z-index: 1;
  }

  .directors_single {
    cursor: pointer;
    margin: 0 0 30px;

    &__img_wrapper {
      position: relative;
      padding-top: calc(350 / 370 * 100%);
      overflow: hidden;

      img {
        transition: 0.7s all ease;
        transform: scale(1.01);
      }
    }

    &__content {
      padding-top: 20px;

      p {
        color: #9B9B9B;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        margin-top: 5px;
        @media(min-width: 1500px ){
          font-size: 14px;
        }
      }

      h3 {
        transition: 0.7s all ease;
        color: #222222;
        margin: 0;
        font-size: 24px;
        line-height: 38px;
        font-weight: 400;
      }
    }

    &:hover {
      .directors_single__img_wrapper {
        img {
          transform: scale(1.04);

        }
      }
    }
  }

`;
export default BodV2;
