import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container} from "react-bootstrap";
import {Link} from "react-router-dom"
import {hover, title} from "../../styles/globalStyleVars";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slick from "react-slick";
import reactHtmlParser from "react-html-parser";

const HomeBannerV2 = ({data}) => {

    const [offset, setOffset] = useState()
    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft)
    }, [])

    const slickRef = useRef()



    function RightArrow(props) {
        const {className, style, onClick, currentSlide, slideCount} = props;
        return (
            //className={`arrow-container ${currentSlide === slideCount - 1 ? " slick-disabled" : ""}`}
            <Container className={`arrow-container`}>
                <li className="go-right" onClick={onClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="5.224" height="9.033" viewBox="0 0 5.224 9.033">
                        <g id="Group_24192" data-name="Group 24192" transform="translate(-1597.293 -1581.293)">
                            <line id="Line_3789" data-name="Line 3789" x2="3.81" y2="3.81" transform="translate(1598 1582)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                            <line id="Line_3790" data-name="Line 3790" y1="3.81" x2="3.81" transform="translate(1598 1585.81)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                        </g>
                    </svg>

                </li>
            </Container>

        );
    }

    function LeftArrow(props) {
        const {className, style, onClick, currentSlide, slideCount} = props;
        return (
            // className={`arrow-container ${currentSlide === 0 ? " slick-disabled" : ""}`}>
            <Container className={`arrow-container`}>
                <li className="go-left" onClick={onClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="5.414" height="9.414" viewBox="0 0 5.414 9.414">
                        <g id="Group_24191" data-name="Group 24191" transform="translate(0.707 0.707)">
                            <line id="Line_3789" data-name="Line 3789" x1="4" y2="4" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                            <line id="Line_3790" data-name="Line 3790" x1="4" y1="4" transform="translate(0 4)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                        </g>
                    </svg>
                </li>
            </Container>
        );
    }

    const sliderChangeSpeed = 5000;
    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        speed: 1400,
        autoplaySpeed: sliderChangeSpeed,
        pauseOnHover: false,
        // focusOnSelect: false,
        // simulateTouch: false,
        nextArrow: <RightArrow/>,
        prevArrow: <LeftArrow/>,
        draggable: false,
        // responsive: [
        //     {
        //         breakpoint: 991,
        //         settings: {
        //
        //             speed: 400,
        //             // autoplay: false
        //         }
        //
        //     }
        // ],
        onInit: function () {
            let getTheLists = document.querySelectorAll('.slick-list .slick-slide:not(.slick-active)');
            setLists(getTheLists)
        },
        beforeChange: function () {
            let getTheLists = document.querySelectorAll('.slick-list .slick-slide:not(.slick-active)');
            setLists(getTheLists)
        },
        // afterChange: function (e) {
        //     if ((homeSlider?.length - 1) === e && window.scrollY <= window.innerHeight) {
        //         setTimeout(() => {
        //             slickRef.current.slickGoTo(0);
        //         }, sliderChangeSpeed)
        //     }
        // }
        afterChange: function (e) {
            if ((1) === e && window.scrollY <= window.innerHeight) {
                setTimeout(() => {
                    slickRef.current.slickGoTo(0);
                }, sliderChangeSpeed)
            }
        }
    };
    // on scroll slider off and on


    const [lists, setLists] = useState([])
    useEffect(() => {
        lists.forEach(e => {
            e.addEventListener('mouseover', () => {
                document.querySelector('.slick-active').classList.add('transform')
            })
            e.addEventListener('mouseout', () => {
                document.querySelector('.slick-active').classList.remove('transform')
            })
        })
    }, [lists])

    useEffect(() => {

        setTimeout(() => {
            if (document.querySelector('.banner-slider')) {
                document.querySelector('.banner-slider').classList.add('active-slide')
            }

        }, 400)
    }, [])


    // slider click handler
    const handleSliderClick = (e) => {
        slickRef.current.slickGoTo(e);
    }

    return (
        <StyledBanner offset={offset} sliderChangeSpeed={sliderChangeSpeed} className='banner-slider'>
            {data &&
                <Slick {...settings} ref={slickRef}>
                    {data?.length > 0 && data?.map((e,i) =>{
                        return(
                            <div onClick={() => handleSliderClick(i)}
                                 className="banner-slider__single" key={i}>
                                <img src={e?.images?.list?.filter(image => image.slider === 'on')?.[0]?.full_path}/>
                                <div className="banner-slider__single__content">
                                    <p>{reactHtmlParser(e?.product_data?.short_desc)}</p>
                                    <h1><Link to={`/project/${e?.product_data?.slug}`}>{reactHtmlParser(e?.product_data?.title)}</Link></h1>
                                    <p>{'Explore'}</p>
                                    <div className="line"/>
                                </div>
                            </div>
                        );
                    })}
                </Slick>
            }
        </StyledBanner>
    );
};

const StyledBanner = styled.section`
  height: calc(100vh);
  position: relative;
  background-color: #000;


  .banner-slider__single {
    position: relative !important;
    height: calc(100vh);
    //padding: 0 15px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.35);
      height: 100vh;
      z-index: 2;
      width: 100%;
      //display: none;
      @media (max-width: 768px) {
        opacity: 0;
        transition: opacity .3s ease;
      }
    }

    @media (max-width: 768px) {
      &__content {
        opacity: 0;
        transition: opacity .3s ease;
      }
    }


    .line {
      top: 0;
      bottom: -290px;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 2;
      position: absolute;
      width: 55%;
      height: 1px;

      &:before {
        content: '';
        top: 0px;
        bottom: 0px;
        left: 0;
        z-index: 2;
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.50);
      }

      &:after {
        content: '';
        width: 0;
        height: 2px;
        background-color: #ffffff;
        position: absolute;
        top: -0.5px;
        bottom: 0px;
        left: 0;
        z-index: 3;
        transition: width ${props => props.sliderChangeSpeed}ms cubic-bezier(0.4, 0, 0, 1);
      }
    }

    img {
      transition: all .6s ease !important;
      display: block !important;
      opacity: 1 !important;
      filter: brightness(0.4);
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    a {
      position: absolute;
      left: -10px;
      top: 0px;
      right: 70%;
      bottom: 0;
      margin: auto;
      height: fit-content;
      width: fit-content;
      color: rgba(255, 255, 255, 0.29) !important;
      font-size: 68px;
      line-height: 50px;
      font-weight: 300;
      text-transform: uppercase;
      transition: all 2.5s ease;
      transition-delay: .6s;
      z-index: 4;
      font-family: ${title};
      text-wrap: nowrap;
      @media(max-width: 767px) {
        text-wrap: balance;
      }
    }

    p {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 5;
      color: rgba(255, 255, 255, 0.85);
      top: 0;
      bottom: 0px;
      width: fit-content;
      height: fit-content;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 2px;
      text-align: center;

      &:nth-last-of-type(1) {
        bottom: -100px;
      }

      &:nth-of-type(1) {
        bottom: 130px;
      }
    }

  }


  .slick-dots {
    bottom: 20px;

    li {
      height: 5px;

      button {
        width: 20px;
        height: 5px;
        background-color: #fff;
        opacity: 1;
        border-radius: 5px;
        position: relative;
        padding: 0;

        &:before {
          width: 0px;
          height: 100%;
          left: 0;
          font-size: 0;
          background-color: ${hover};
          opacity: 1;
          border-radius: 5px;

        }
      }

      &.slick-active {
        button:before {
          width: 100%;
          transition: width 4.3s ease;
        }
      }
    }

  }

  .slick-list {
    .slick-slide {
      z-index: 9;
      transition: transform .5s ease !important;
    }

    .slick-slide:not(.slick-active) {
      transform: translateX(-100px) !important;

      img {
        transform: translateX(100px);

      }
    }

    .slick-active {

      @media (max-width: 768px) {
        .banner-slider__single:before {
          opacity: 1;
          transition-delay: .4s;
        }

        .banner-slider__single__content {
          opacity: 1;
          transition-delay: .4s;
        }
      }

      a {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        transition-delay: .0s;
        color: rgba(255, 255, 255, 0.85) !important;
      }

      img {
        filter: none;
      }

      &:hover {
        transform: none !important;
      }
    }
  }

  .slick-slide:not(.slick-active) {
    &:hover {
      transform: translateX(-150px) !important;
    }
  }

  .slick-active.transform {
    transform: translateX(-50px) !important;
  }

  //&.active-slide .slick-active {
  //  .banner-slider__single .line:after {
  //    width: 100%;
  //  }
  //}

  .slick-active {
    .banner-slider__single .line:after {
      width: 100%;
    }
  }

  .go-left, .go-right {
    position: absolute;
    bottom: 50px;
    z-index: 99;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    transition: all .5s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    
    &:hover{
      border: 1px solid #ffffff;
    }
      
  }

  .go-right {
    right: ${props => props.offset + 15}px;

    @media (max-width: 767px) {
      right: unset !important;
      left: 75px !important;
    }
    }

    @media (max-width: 767px) {
      right: unset !important;
      //left: 80px !important;
    }
  }


  .go-left {
    right: ${props => props.offset + 80}px;

    @media (max-width: 767px) {
      right: unset !important;
      left: 15px !important;
    }
  }

  @media (max-width: 991px) {
    .slick-list {
      .slick-slide:not(.slick-active) {
        transform: none !important;
      }

      .slick-slide {

        img {
          transform: none !important;
        }

        .banner-slider__single a {
          left: 0 !important;
        }
      }
    }

  }


  @media (max-width: 768px) {
    .banner-slider__single {
      padding: 0 15px;
      align-items: center;
      //justify-content: center;
      display: flex !important;

      &__content {
        width: 100%;

        p, a {
          position: relative;
          left: 0;
          right: auto;
          text-align: left;
          margin-left: initial;
        }

        a {
          font-size: 40px;
          line-height: 50px;
          margin-top: 20px !important;
          padding-bottom: 20px;
          display: block;
          margin-left: initial !important;
          text-align: left;
        }

        .line {
          position: relative;
          bottom: auto;
          left: 0;
          right: auto;
          margin-left: initial;
          width: 100%;
          margin-top: 30px;
        }
      }

    }
  }

  //.slick-current {
  //  pointer-events: none;
  //}


`;


export default HomeBannerV2;
