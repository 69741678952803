import React, { useEffect, useState, useRef } from 'react';
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, Pagination, EffectFade} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Img } from "../Img";
import reactHtmlParser from "react-html-parser";
import {title} from "../../styles/globalStyleVars";

const MyComponent = ({ data }) => {
    const [offset, setOffset] = useState(90);
    const [total, setTotal] = useState(0);
    const [currentSlide, setCurrentSlide] = useState(1);

    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft);
        setTotal(data?.posts?.list?.length || 0);
    }, [data]);

    return (
        <StyledComponent className={`SliderCount`}>
            <Container fluid className={'p-0'}>
                <Row>
                    <Col md={6} className={'p-0'}>
                        <div className="slider-wrapper-left">
                            <div className="slider-wrapper-left__init">
                                <Swiper
                                    modules={[Navigation, Pagination, EffectFade]}
                                    effect="fade"
                                    navigation={{
                                        prevEl: '.prev_swipper',
                                        nextEl: '.next_swipper',
                                    }}
                                    pagination={false}
                                    onSlideChange={(swiper) => setCurrentSlide(swiper.realIndex + 1)}
                                >
                                    {data?.posts?.list && data?.posts?.list?.length > 0 && data?.posts?.list.map((e, i) => (
                                        <SwiperSlide key={i}>
                                            <div className="single-item">
                                                <div className="image-wrapper">
                                                    <Img src={e?.images[0]?.full_path} />
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className={'p-0'}>
                        <div className="slider_wrapper_right">
                            <div className="slider_wrapper_right__init" style={{ paddingRight: offset + 15 + 'px' }}>
                                <Swiper
                                    modules={[Navigation, Pagination]}
                                    navigation={{
                                        prevEl: '.prev_swipper',
                                        nextEl: '.next_swipper',
                                    }}
                                    pagination={false}
                                    slidesPerView={1}
                                    onSlideChange={(swiper) => setCurrentSlide(swiper.realIndex + 1)}
                                >
                                    {data?.posts?.list && data?.posts?.list?.length > 0 && data?.posts?.list.map((e, i) => (
                                        <SwiperSlide key={i}>
                                            <div className="single-item_content">
                                                <h3 className={''}>{e?.data?.title}</h3>
                                                <p className={''}>
                                                    {reactHtmlParser(e?.data?.description)}
                                                </p>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                                <div className="footer">
                                    <div className="count">
                                        <p><span id={'current-slider'} className="current">{currentSlide}</span>/ <span className="total">{total}</span></p>
                                    </div>
                                    <ul className="slider-nav">
                                        <li className={'prev_swipper hover'} id={'slider_prev'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="7.828" height="12.828" viewBox="0 0 7.828 12.828">
                                                <g id="Group_6" data-name="Group 6" transform="translate(1.414 11.414) rotate(-90)">
                                                    <line id="Line_4" data-name="Line 4" y1="5" x2="5" fill="none" stroke="#1c1c1c" strokeLinecap="round" strokeWidth="1" />
                                                    <line id="Line_5" data-name="Line 5" x1="5" y1="5" transform="translate(5)" fill="none" stroke="#1c1c1c" strokeLinecap="round" strokeWidth="1" />
                                                </g>
                                            </svg>
                                        </li>
                                        <li className={'next_swipper hover'} id={'slider_next'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="7.828" height="12.828" viewBox="0 0 7.828 12.828">
                                                <g id="Group_6" data-name="Group 6" transform="translate(-92.086 454.914) rotate(-90)">
                                                    <line id="Line_4" data-name="Line 4" x2="5" y2="5" transform="translate(443.5 93.5)" fill="none" stroke="#1c1c1c" strokeLinecap="round" strokeWidth="1" />
                                                    <line id="Line_5" data-name="Line 5" x1="5" y2="5" transform="translate(448.5 93.5)" fill="none" stroke="#1c1c1c" strokeLinecap="round" strokeWidth="1" />
                                                </g>
                                            </svg>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    background: #000000;
    position: relative;
    overflow: hidden;

    .container, .container-fluid {
        position: relative;
        z-index: 2;
    }

    .slider-wrapper-left {
        &__init {
            @media (max-width: 992px) {
                .swiper-container {
                    margin-bottom: -8px !important;
                }
            }

            .single-item {
                .image-wrapper {
                    padding-top: calc(520 / 698 * 100%);
                    position: relative;
                    overflow: hidden;

                    @media (max-width: 992px) {
                        height: 100%;
                    }

                    .content {
                        position: absolute;
                        z-index: 99;
                        top: 70px;

                        @media (max-width: 992px) {
                            display: none;
                        }

                        p {
                            font-size: 40px;
                            line-height: 48px;
                            color: #E9E4DC;
                        }
                    }
                }
            }
        }
    }

    .slider_wrapper_right {
        height: 100%;

        .slider_wrapper_right__init {
            background: #f4f4f4;
            padding: 70px 0 70px 70px;
            height: calc(100% - 20%);
            position: relative;

            .single-item_content {
                h3 {
                    font-family: ${title};
                    font-size: 24px;
                    line-height: 34px;
                    font-weight: 500;
                    margin-bottom: 30px;
                    color: #000000;
                }

                p {
                    color: #000000;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                }
            }
        }
    }

    .footer {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        left: 0;
        right: 0;
        padding: 0 70px;
        bottom: 40px;

        .count {
            p {
                color: #000;
                font-size: 16px;
                font-weight: 600;
                line-height: 28px;
            }

            .current {
                font-size: 32px;
                font-weight: 600;
                line-height: 44px;
                color: #000;
                margin-right: 8px;
            }

            .total {
                color: #000;
                font-size: 16px;
                font-weight: 600;
                line-height: 28px;
            }
        }

        ul {
            gap: 24px;
            position: relative;
            display: flex;

            li {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #1c1c1c;
                position: relative;
                border-radius: 50%;
                height: 40px;
                width: 40px;

                svg {
                    position: relative;

                    #Ellipse_4378 {
                        transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                        r: 0;
                    }

                    &:hover {
                        #Ellipse_4378 {
                            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                            r: 26px;
                        }
                    }
                }

                &:hover{
                    svg{
                        g{
                            line{
                                stroke: #ffffff;
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 1124px) {
        .slider-wrapper-left {
            &__init {
                .single-item {
                    .image-wrapper {
                        padding-top: calc(700 / 728 * 100%);
                    }
                }
            }
        }
    }
    @media (max-width: 992px) and (min-width: 768px) {
        .slider-wrapper-left {
            height: 100%;

            .slider-wrapper-left__init {
                height: 100%;

                .swiper-container {
                    height: 100%;

                    .swiper-wrapper {
                        height: 100%;

                        .swiper-slide {
                            height: 100%;

                            > div {
                                height: 100%;
                            }

                            .single-item {
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 992px) {
        &:after {
            height: 100px;
            z-index: 0;
        }

        .slider-wrapper-left {
            &__init {
                .swiper-container {
                    margin-bottom: -8px !important;
                }

                .single-item {
                    .image-wrapper {
                        height: 100%;
                        .content {
                            display: none;
                        }
                    }
                }
            }
        }

        .slider_wrapper_right {
            .slider_wrapper_right__init {
                //display: flex;
                flex-direction: column;
                height: auto;
                padding: 70px 30px;

                .single-item_content {
                    h3 {
                        display: block;
                        font-size: 24px;
                        line-height: 30px;
                        color: #000;
                        margin: 0 0 30px;
                    }
                }
            }
        }

        .footer {
            position: relative;
            margin-top: 0;
            margin-bottom: 30px;
            padding: 0;
            left: unset;
            right: unset;
            bottom: unset;
            flex-direction: row-reverse;

            .count {
                padding-right: 15px;
            }

            .slider-nav {
                li {
                    &:first-child {
                        position: relative;
                        left: unset;
                        transform: unset;
                        top: 0;
                    }

                    &:last-child {
                        position: relative;
                        left: 0px;
                        right: unset;
                        transform: unset;
                        top: 0;
                    }
                }
            }

            .swiper-container {
                margin: 0 0 -10px;
            }
            
            
            @media(max-width: 992px){
                margin-top: 30px;
            }
        }
    }
`;

export default React.memo(MyComponent);
