import React  from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import Subtitle from "./SubTitle";
import {Link} from "react-router-dom";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import reactHtmlParser from "react-html-parser";
import {title} from "../styles/globalStyleVars";

const MyComponent = ({data, list}) => {

    return (
        <StyledComponent>
            <Container className={'associates pt-150 pb-150'}>
                <Row>
                    <Col md={12}>
                        <Subtitle text={data?.section_data?.subtitle} marginBottom={40}/>
                        <h5>{data?.section_data?.short_desc}</h5>
                    </Col>
                </Row>
                <Row className={'unit d-flex'}>
                    {
                        data?.posts?.list && data?.posts?.list?.length>0 &&
                        data?.posts?.list?.map((e, i)=>{
                            return(
                                <Col md={4} className={'unit__single'} key={i}>
                                    <div className={'unit__single__img'}>
                                        <div className={'image-wrapper'}>
                                            <img src={e?.images?.[0]?.full_path}/>
                                        </div>
                                        <div className={'content'}>
                                            <p>
                                                {e?.data?.short_desc}
                                            </p>
                                            {
                                                e?.data?.subtitle &&
                                                <span>
                                                <Link to={e?.data?.subtitle}>
                                                    <h6>Read More</h6>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="31.207"
                                                         height="17.414" viewBox="0 0 31.207 17.414">
                                                      <g id="Group_5742" data-name="Group 5742"
                                                         transform="translate(-1838 688.207)">
                                                        <line id="Line_59" data-name="Line 59" x2="8" y2="8"
                                                              transform="translate(1860.5 -687.5)" fill="none"
                                                              stroke="#fff" strokeLinecap="round" stroke-width="1"/>
                                                        <line id="Line_60" data-name="Line 60" y1="8" x2="8"
                                                              transform="translate(1860.5 -679.5)" fill="none"
                                                              stroke="#fff" strokeLinecap="round" stroke-width="1"/>
                                                        <line id="Line_61" data-name="Line 61" x1="29"
                                                              transform="translate(1838.5 -679.5)" fill="none"
                                                              stroke="#fff" strokeLinecap="round" stroke-width="1"/>
                                                      </g>
                                                    </svg>
                                                </Link>
                                            </span>
                                            }
                                        </div>
                                    </div>
                                </Col>
                            );
                        })
                    }
                </Row>
            </Container>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    background-color: #f5f5f5;

    h5 {
        font-family: ${title};
        color: #1C1C1C;
        font-size: 40px;
        line-height: 48px;
    }

    .unit {
        margin-top: 60px;

        &__single {
            margin-bottom: 30px;

            &__img {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fefefe;
                //border: 1px solid #000;
                position: relative;
                overflow: hidden;

                &::after {
                    position: absolute;
                    content: '';
                    bottom: 0;
                    left: 0;
                    height: 0px;
                    width: 100%;
                    background-color: #0d0a0a !important;
                    transition: height 0.3s ease-in-out;
                }

                margin: auto;
                height: 250px;
                width: 100%;
                transition: background-color 0.5s ease-in-out, padding 0.5s ease-in-out;

                image {
                    width: 100%;
                    height: 100%;
                }

                .image-wrapper {
                    img {
                        width: 180px;
          }
        }
        @media(max-width: 767px){
          height: 220px;
        }
        .content{
          transition: 0.7s ease-in-out;
          display: none;
          p{
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            margin-bottom: 40px;
            color: #F9F9F9;
          }
          span{
            a{
              display: flex;
              h6{
                margin-right: 10px;
                color: #F9F9F9;
              }
            }
          }
        }
        &:hover{
          cursor: pointer;
          .image-wrapper{
            display: none;
          }
          .content{
            display: block;
            transition: 0.7s ease-in-out;
            padding: 30px 80px 40px 30px;
            position: relative;
            z-index: 9;
          }
          //background-color: #0d0a0a;
          
          &::after{
            height: 100% !important;
            transition: height 0.3s ease-in-out;
          }
          
        }
      }
      @media(max-width: 767px){
        margin-bottom: 20px;
      }
      h6{
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
      }
    }
    @media(max-width: 767px){
      margin-top: 40px;
    }
  }

`;




export default React.memo(MyComponent);
