import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import ResponsiveMasonry from "react-responsive-masonry/lib/ResponsiveMasonry";
import Masonry from "react-responsive-masonry/lib/Masonry";
import {LightgalleryItem, LightgalleryProvider} from "react-lightgallery";
import {Container} from "react-bootstrap";

const MyComponent = ({background, topBg, titleColor, img, data}) => {


    const PhotoItem = ({image, thumb, group}) => (
        <LightgalleryItem group={group} src={image} thumb={thumb}>
            <img alt='' src={image} style={{width: "100%"}}/>
        </LightgalleryItem>
    );

    //--- padding top
    useEffect(() => {
        setTimeout(() => {
            let getItems = document.querySelectorAll('.react_lightgallery_item');
            getItems.forEach(e => {
                e.parentNode.classList.add('parent')
            })
        }, 300)

        setTimeout(() => {
            var getParent = document.querySelectorAll('.parent');
            if (getParent?.length >= 0) {
                getParent[0].style.paddingTop = '80px';
            }
            if (window.innerWidth > 767) {
                if (getParent?.length > 1) {
                    if (getParent[2]) {
                        getParent[2].style.paddingTop = '90px';
                    }

                }
                if (getParent?.length > 2) {
                    getParent[3].style.paddingTop = '150px';
                }
            }


        }, 600)

    }, [])

    // offset get
    const [offset, setOffset] = useState(90)
    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft)
    }, [])


    return (
        <StyledComponent id={'gallery'} background={background} topBg={topBg} className={'gallery'}>
            <div className={'gallery__wrap'} >
                <LightgalleryProvider
                    lightgallerySettings={
                        {
                            download: false,
                            thumbnail: false,
                            fullScreen: true,
                            share: false
                        }
                    }
                >

                    <ResponsiveMasonry
                        columnsCountBreakPoints={{750: 2, 900: 4}}
                    >

                        <Masonry gutter={'10px'}>
                            {
                                data && data?.length > 0 &&
                                data?.map((e,i)=>{
                                    return(
                                        <PhotoItem key={i} image={e?.full_path} group={'1'}/>
                                    );
                                })
                            }
                        </Masonry>
                    </ResponsiveMasonry>
                </LightgalleryProvider>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    padding-top: 40px;
  background-color: ${p => p.background || 'transparent'};
  position: relative;
  overflow: hidden;
  min-height: 100vh;

  .gallery__wrap {
    position: relative;
    z-index: 3;
  }

  .react_lightgallery_item {
    cursor: pointer;


  }

  .db-btn {
    background-color: #736640;
  }

  .gallery-shadow {
    position: absolute;
    width: 50%;
    left: 0;
    z-index: 1;
    top: 70px;
  }

  .react_lightgallery_item {
    background-color: #DDD;
  }

  @media (max-width: 991px) {
    .gallery-shadow {
      top: 80px;
    }
  }

  @media (max-width: 850px) {
    .gallery-shadow {
      top: 110px;
    }
  }


  @media (max-width: 767px) {
    .gallery__wrap {
      padding-top: 50px;
      padding-left: 0 !important;
    }

    .gallery-shadow {
      top: 30px;
    }
  }

  @media (max-width: 575px) {

    .gallery-shadow {
      top: 70px;
    }
  }





`;

export default MyComponent;