import React, {useEffect, useRef} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import {hover, title} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";
import {Link} from "react-router-dom";
import { gsap, CSSPlugin } from "gsap/all";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(CSSPlugin, ScrollTrigger);
gsap.registerPlugin(ScrollTrigger)
const MyComponent = ({data}) => {
    // let slide = data?.val
    // Create a reference to the StyledComponent element
    const ref = useRef(null);
    //
    // // Animate the background-position property of the StyledComponent element
    // parallax animation
    useEffect(() => {
        // gsap.utils.toArray(".reel-values__boxes__img").forEach((section, i) => {
        //     gsap.to(section, {
        //         yPercent: -25,
        //         scrollTrigger: {
        //             trigger: section,
        //             scrub: true,
        //         }
        //     })
        // });

        gsap.utils.toArray(".box").forEach((section, i) => {
            gsap.to(section, {
                yPercent: -10,
                scrollTrigger: {
                    trigger: section,
                    scrub: true,
                }
            })
        });
    }, [data])
    return (
        <StyledComponent className={'reel-values pb-150'} ref={ref}>
            <Container>
                <Row>
                    <Col className={'reel-values__boxes'}>
                        <Row>
                            {
                                data && data?.length > 0 &&
                                data?.map((e,i)=>{
                                    return(
                                        <Col sm={6}>
                                            <Link to={`project/${e?.product_data?.slug}`}>
                                                <div className="reel-values__boxes__img">

                                                    <Img src={e?.images?.list?.filter(image => image?.slider === 'on')?.[0]?.full_path}/>
                                                    <h4 className={'box'} data-speed={0.9}>{i+1}</h4>
                                                </div>
                                                <div className={'reel-values__boxes__bottom'}>
                                                    <h6>{reactHtmlParser(e?.product_data?.title)}</h6>
                                                    <ul>
                                                        <li>{reactHtmlParser(e?.product_data?.type)}</li>
                                                        <li>{e?.product_data?.location}</li>
                                                    </ul>
                                                </div>
                                            </Link>
                                        </Col>
                                    );
                                })
                            }
                        </Row>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  padding-top: 220px;
  .reel-values__boxes {
    padding-left: 100px;
    padding-right: 100px;
    @media(max-width: 767px){
      padding-left: 15px;
      padding-right: 15px;
    }
    .col-sm-6 {
      margin-bottom: 80px;

      &:nth-of-type(odd) {
        padding-right: 100px;
      }

      &:nth-of-type(even) {
        padding-top: 150px;
        padding-left: 100px;
      }

      &:nth-last-child(2) {
        margin-bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
      @media(max-width: 767px){
        padding-left: 15px;
      }
    }

    &__img {
      padding-top: calc(450 / 400 * 100%);
      position: relative;
     
      .global-image{
        overflow: hidden;
        img{
          filter: grayscale(1);
          transform: scale(1);
          transition: all 0.3s ease-in-out;
        }
      }
      &:hover{
        .global-image{
          img{
            transform: scale(1.03);
            transition: all 0.3s ease-in-out;
          }
        }
      }

      h3 {
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        color: #ffffff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
      }

      h4 {
        font-family: ${title};
        font-size: 160px;
        line-height: 160px;
        font-weight: 300;
        color: #484848;
        position: absolute;
        top: 0;
        left: 40px;
        z-index: 2;
        transform: translateY(-50%);
      }
      
      &:hover{
        .global-image{
          img{
            filter: unset;
          }
        }
      }
    }
    
    &__bottom{
      margin-top: 20px;
      h6{
        font-size: 24px;
        //line-height: 38px;
        font-weight: 400;
        color: #1C1C1C;
        margin-bottom: 5px !important;
      }
      ul{
        display: flex;
        gap: 30px;
       list-style: none;
        li{
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          color: #9B9B9B;
          position: relative;
          text-transform: capitalize;
          &:nth-child(2)::before {
            content: '';
            display: inline-block;
            width: 7px;
            height: 7px; 
            background-color: #9B9B9B;
            border-radius: 50%;
            position: absolute;
            left: -20px;
            top: 7px;
          }
        }
      }
    }

    p {
      font-size: 16px;
      line-height: 24px;
      color: #170C3D;
      margin-top: 20px;
    }
  }

  @media (min-width: 1600px) {
    .reel-values__title h3 {
      transform: rotate(-90deg) translateY(22%);
    }
  }

  @media (max-width: 991px) {
    padding-top: 80px;
    .reel-values__boxes {
      .col-sm-6 {
        margin-bottom: 40px;

        &:nth-of-type(odd) {
          padding-right: 30px;
        }

        &:nth-of-type(even) {
          padding-top: 100px;
          padding-left: 30px;
        }
      }

      &__img h3 {
        font-size: 25px;
        line-height: 28px;
        //left: 15px;
        //right: 15px;
      }
    }
  }

  @media (min-width: 768px) {
    .mobile {
      display: none;
    }
  }

  @media (max-width: 991px) {
    .reel-values__title h3 {
      transform: rotate(-90deg) translateY(50px);
    }
  }

  @media (max-width: 767px) {
      padding-top: 140px !important;
    padding-bottom: 100px !important;
    .desktop {
      display: none;
    }

    .mobile {
      display: unset;
    }

    .reel-values__title {
      min-width: 100%;
      margin-bottom: 130px;

      h1 {
        transform: none;
        white-space: normal;
        position: relative;
        left: 0;
        right: auto;
        width: auto;
      }
    }

    .reel-values__boxes {
      min-width: 100%;

      .col-sm-6 {
        padding-right: 15px !important;
        padding-left: 15px !important;

        &:nth-of-type(even) {
          padding-top: 0;
        }

        &:last-child {
          margin-top: 120px;
        }
      }

      &__img {
        h3 {
        }
      }
    }

  }

  @media (max-width: 575px) {
    //padding-bottom: 0 !important;
    .reel-values__boxes {
      .col-sm-6 {
        margin-bottom: 120px;
      }

      &__img {
        h4 {
          left: 15px;
        }

      }


      p {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

`;

export default MyComponent;
