import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";
import {ImgInnerBanner} from "../ImgInnerBanner";
import {ImageParallaxNoLoad} from "../ImageParallaxNoLoad";

const MyComponent = ({data}) => {

    const [offset, setOffset] = useState(90)

    useEffect(() => {
        setOffset(document.querySelector(' .container').offsetLeft)
    }, [])


    const rows = [
        {
            title: 'Background',
            content:
                'LANCO DEVELOPMENTS LTD. (ldl) is a pioneer real estate company in Bangladesh. We are a growing & upcoming construction company.  We started our journey through construction of residential, commercial and industrial projects with the focus on returning maximum profits both in financial and non-financial aspects for our clients. Over the years, we have expanded our operations from Dhaka to Chattogram and our vision is To provide viable housing solutions to every segment of our society.<br/> <br/>' +
                ' Lanco Development is a reputable real estate development company known for its commitment to quality and innovation. With a strong focus on creating exceptional residential and commercial properties, Lanco Development Lanco Development is a reputable real estate development company known for its commitment to quality and innovation. ',
            imageSrc: '/images/dynamic/mission/mission.jpg',
        },
        {
            title: 'Who We Are',
            content:
                'LANCO DEVELOPMENTS LTD. (ldl) is a pioneer real estate company in Bangladesh. We are a growing & upcoming construction company.  We started our journey through construction of residential, commercial and industrial projects with the focus on returning maximum profits both in financial and non-financial aspects for our clients. Over the years, we have expanded our operations from Dhaka to Chattogram and our vision is To provide viable housing solutions to every segment of our society.<br/> <br/>' +
                ' Lanco Development is a reputable real estate development company known for its commitment to quality and innovation. With a strong focus on creating exceptional residential and commercial properties, Lanco Development Lanco Development is a reputable real estate development company known for its commitment to quality and innovation. ',
            imageSrc: '/images/dynamic/story/who.jpg',
        },
    ];

    return (
        <StyledComponent className={''} offset={offset}>
            <Container fluid>
                { data && data?.length > 0 &&
                    data?.map((row, index) => (
                    <Row key={index + 1} className={`values pt-150 pb-150 ${index % 2 === 1 ? 'even-row' : 'odd-row'}`} style={{paddingLeft: offset + 15 + 'px', paddingRight: offset + 15 + 'px'}} >
                        {
                        }
                        <Col md={index % 2 === 1 ? {span:5, offset:1} : 5}
                             className={'values__image'}
                        >
                            <div className={'img-wrapper'}>
                                <ImageParallaxNoLoad src={row?.images?.[0]?.full_path} />
                            </div>
                        </Col>
                        <Col  md={index % 2 === 1 ? 6 : {span:6,offset:1}}
                              className={'values__content'} >
                            <div className={'content-wrapper'}>
                                <h5>{row?.data?.title}</h5>
                                <p className={'split-up'}>{reactHtmlParser(row?.data?.description)}</p>
                            </div>
                        </Col>

                    </Row>
                ))}
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .values{

    &:nth-child(even){
      flex-direction: row-reverse;
    }
    &__content{
      .content-wrapper{
        h5{
          margin-bottom: 40px;
          color: #1C1C1C;
        }
        p{
          margin-bottom: 40px;
          color: #1C1C1C;
        }
        ul{
          li{
            color: #000000;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            padding-bottom: 15px;
            margin-bottom: 28px;
            border-bottom: 1px solid #EAEAEA;
            &:last-child{
              margin-bottom: 0;
            }
          }
        }
      }
      @media(max-width: 767px){
        margin-top: 40px;
      }
    }
    &__image{
      .img-wrapper{
        position: relative;
        padding-top: calc(450 / 500 * 100%);
        overflow: hidden;
      }
    }
    @media(max-width: 767px){
      padding-left: 15px;
      padding-bottom: 15px;
    }
  }

  .even-row{
    background-color: #ffffff;

  }
`;

export default MyComponent;
