import React, {useEffect} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {gsap} from "gsap";
import Button from "../Button";
import SubTitle from "../SubTitle";
import reactHtmlParser from "react-html-parser";
import {Img} from "../Img";
import {title} from "../../styles/globalStyleVars";


const Counter = ({data}) => {

    return (
        <>
            <StyledDetail className='pt-150'>
                <div className={'shadow'}>
                    <Img src={'/images/static/pattern.png'}/>
                </div>
                <Container>
                    <SubTitle text={data?.section_data?.title} marginBottom={'60'} color={'#1C1C1C'}  />
                    <Row>
                        <Col md={6} className={'counter-left pr-0 pb-150'}>
                            <div className="content">
                                <h5>{reactHtmlParser(data?.section_data?.subtitle)}</h5>
                            </div>
                        </Col>
                        <Col className={'counter-right'} md={6}>
                            <div className="content-top">
                                {reactHtmlParser(data?.section_data?.short_desc)}
                                <Button text={'Read More'}
                                        marginTop={40} background={'transparent'} color={'#1C1C1C'}
                                        hoverBackground={'#535353'} src={'/our-story'}/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={5} className={'bottom-left'}>
                            <div className={'image'}>
                                <Img src={data?.images?.list[0]?.full_path}/>
                                <div className={'image-top'}  data-speed={1.2}>
                                    <img src={data?.images?.list[1]?.full_path}/>
                                </div>
                            </div>
                        </Col>
                        <Col md={{offset:1, span:6}} className={'bottom-right'}>
                            <div className={'content-bottom'}>
                                <SubTitle  text={'Lanco Promises'} fontSize={20} lineHeight={34} fontWeight={500} color={'#1C1C1C'}/>
                                {reactHtmlParser(data?.section_data?.description)}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </StyledDetail>
        </>

    );
};

const StyledDetail = styled.section`
  background-color: #ffffff;
  position: relative;
  padding-bottom: 250px;
  @media(max-width: 767px){
    padding-bottom: 80px;
  }
  .shadow{
    position: absolute;
    height: 100%;
    width: 100%;
    bottom: 0;
  }
  .counter-left{
    .content{
      margin-right: 100px;
      margin-bottom: 85px;
      h5{
        font-family: ${title};
        color: #1C1C1C;
      }
    }
  }

  .counter-right{
    .content-top{
      p{
        font-size: 16px;
        line-height: 24px;
        color: #1C1C1C;
      }
      h6{
        margin-top: 20px;
        font-size: 18px;
        line-height: 24px;
        font-weight: bold;
        color: #F9F9F9;
      }
    }
  }

  .bottom-left{
    .image{
      background-color: #1c7430;
      position: relative;
      padding-top: calc(500 / 400 * 100%);
      .image-top{
        position: absolute;
        padding-top: calc(200 / 300 * 100%);
        width: 100%;
        height: 100%;
        right: -20%;
        bottom: -90px;
        padding-bottom: 150px;
        @media(min-width: 1550px){
          right: -40%;
        }
      }
    }
  }
  .bottom-right{
    .content-bottom{
      margin-top: 94px;
      ul{
        margin-top: 60px;
        li{
          position: relative;
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          color: #1c1c1c;
          margin-bottom: 30px;
          //border-bottom: 1px solid #656565;
        }
      }
    }
  }
  
  
  @media(max-width: 767px){
    .counter-left{
      padding-bottom: 0 !important;
      margin-right: 15px;
      .content{
        padding-bottom: 0;
        margin-right: 0;
        margin-bottom: 0;
        h5{
          font-size: 30px !important;
          line-height: 38px !important;
        }
      }
      
    }

    .counter-right{
      .content-top{
        margin-bottom: 180px;
        p{
          font-size: 18px;
          line-height: 24px;
          margin-top: 40px;
          margin-bottom: 40px;
        }
        @media(max-width: 767px){
          margin-bottom: 40px;
        }
      }
    }

    .bottom-left{
      .image{
        padding-top: calc(339 / 308 * 100%);
        //width: 308px;
        .global-image{
          width: 85%;
        }
        background-color: unset;
        .image-top{
          //padding-top: calc(173 / 255 * 100%);
          width: 225px;
          height: 100%;
          right: 0px;
          bottom: -50px;
        }
      }
    }
  }
  
  .bottom-right{
    .content-bottom{
      margin-top: 120px;
    }
  }
  
`;

export default React.memo(Counter);
