// reducers/index.js
import { combineReducers } from 'redux';
import loaderReducer from './../loader/index';
import pageReducer1 from './../home/index';
import pageReducer2 from './../about/index';
import pageReducer3 from './../career/index'
import pageReducer4 from './../business/index'
import pageReducer5 from './../buyer/index'
import pageReducer6 from './../contact/index'
import pageReducer7 from './../customer/index'
import pageReducer8 from './../directors/index'
import pageReducer9 from './../landowner/index'
import pageReducer10 from './../management/index'
import pageReducer11 from './../mission/index'
import pageReducer12 from './../news/index'
import pageReducer13 from './../project/index'
import pageReducer14 from './../story/index'
import pageReducer15 from './../subscription/index'
const rootReducer = combineReducers({
    loader: loaderReducer,
    page1: pageReducer1,
    page2: pageReducer2,
    page3: pageReducer3,
    page4: pageReducer4,
    page5: pageReducer5,
    page6: pageReducer6,
    page7: pageReducer7,
    page8: pageReducer8,
    page9: pageReducer9,
    page10: pageReducer10,
    page11: pageReducer11,
    page12: pageReducer12,
    page13: pageReducer13,
    page14: pageReducer14,
    page15: pageReducer15,
    // Add other page reducers here
});

export default rootReducer;
