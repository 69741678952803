import React, { useContext } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { TransitionProvider } from '../context/TransitionContext';
import TransitionComponent from '../components/Transition';
import NewsDetail from '../pages/news/single';
import Career from "../pages/career";
import Landowner from "../pages/landowner";
import Buyer from "../pages/buyer";
import ContactUs from "../pages/contactUs";
import beSmart from "../pages/businessUnits/be-smart";
import Home from "../pages/home";
import About from "../pages/about";
import Story from "../pages/about/our-story";
import Mission from "../pages/about/mission-vision";
import Management from "../pages/about/management";
import Bod from "../pages/about/board-of-director";
import Project from "../pages/project";
import ProjectDetail from '../pages/project/single';
import News from "../pages/news";
import Sheygun from "../pages/businessUnits/sheygun";
import Brokerage from "../pages/businessUnits/brokerage";
import EasyTerm from "../pages/businessUnits/easy-term";
import Property from "../pages/businessUnits/property";
import Line from "../pages/businessUnits/line";
import Customer from "../pages/customer";
import Error from '../pages/404';
import BeSmart from "../pages/businessUnits/be-smart";

const Router = () => {
  const location = useLocation();
  return (

      <TransitionProvider>
        <Routes>
          <Route exact
                 index
                 element={
                   // <TransitionComponent>
                     <Home />
                   // </TransitionComponent>
                 }
          />
          <Route exact
                 path='/our-story'
                 element={
                   // <TransitionComponent>
                     <Story />
                   // </TransitionComponent>
                 }
          />
          <Route exact
                 path='/mission-vision'
                 element={
                   // <TransitionComponent>
                     <Mission />
                   // </TransitionComponent>
                 }
          />
            <Route exact
                 path='/management-board'
                 element={
                   // <TransitionComponent>
                     <Management />
                   // </TransitionComponent>
                 }
          />
            <Route exact
                 path='/board-of-director'
                 element={
                   // <TransitionComponent>
                     <Bod />
                   // </TransitionComponent>
                 }
          />
          <Route exact
                 path='/project'
                 element={
                   // <TransitionComponent>
                     <Project />
                   // </TransitionComponent>
                 }
          />
          <Route exact
                 path='/project/:slug'
                 element={
                   // <TransitionComponent>
                     <ProjectDetail />
                   // </TransitionComponent>
                 }
          />
            <Route exact
                   path='/news-events'
                   element={
                       // <TransitionComponent>
                           <News />
                       // </TransitionComponent>
                   }
            />
            <Route exact
                   path='/news/:slug'
                   element={
                       // <TransitionComponent>
                           <NewsDetail />
                       // </TransitionComponent>
                   }
            />
          <Route exact
                 path='/the-6-interior'
                 element={
                   // <TransitionComponent>
                     <Sheygun />
                   // </TransitionComponent>
                 }
          />
          <Route exact
                 path='/lanco-brokerage'
                 element={
                   // <TransitionComponent>
                     <Brokerage />
                   // </TransitionComponent>
                 }
          />
            {/*<Route exact*/}
            {/*       path='/lanco-easy-term'*/}
            {/*       element={*/}
            {/*           // <TransitionComponent>*/}
            {/*               <EasyTerm />*/}
            {/*           // </TransitionComponent>*/}
            {/*       }*/}
            {/*/>*/}
            <Route exact
                   path='/lanco-property-management'
                   element={
                       // <TransitionComponent>
                           <Property />
                       // </TransitionComponent>
                   }
            />

            <Route exact
                   path='/line-architects-ltd'
                   element={
                       // <TransitionComponent>
                           <Line />
                       // </TransitionComponent>
                   }
            />
            <Route exact
                   path='/be-smart-by-lanco'
                   element={
                       // <TransitionComponent>
                           <BeSmart />
                       // </TransitionComponent>
                   }
            />
            <Route exact
                   path='/landowner'
                   element={

                       <Landowner />
                   }
            />

            <Route exact
                   path='/buyer'
                   element={
                       // <TransitionComponent>
                           <Buyer />
                       // </TransitionComponent>
                   }
            />
            <Route exact
                   path='/customer'
                   element={
                       // <TransitionComponent>
                           <Customer />
                       // </TransitionComponent>
                   }
            />

            <Route exact
                   path='/contact-us'
                   element={
                       // <TransitionComponent>
                           <ContactUs />
                       // </TransitionComponent>
                   }
            />
          <Route exact
                 path='/career'
                 element={
                   // <TransitionComponent>
                     <Career />
                   // </TransitionComponent>
                 }
          />
          {/* Other routes */}
          <Route path="*" element={<Error />} />
        </Routes>
      </TransitionProvider>

  );
};

export default Router;
