import React, {useEffect} from 'react';
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import Overview from "../../components/Overview";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import {fetchCustomerData} from "../../api/redux/customer";
import CustomerForm from "../../components/Customer/CustomerForm";
import OverviewWithProps from "../../components/OverviewWithProps";

const MyComponent = () => {
    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'customers',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
        }

        dispath(fetchCustomerData([api_url , api_params]))
    }, [])


    //Refactor Data

    const getData = useSelector((state) => state.customer);
    const banner = getData?.posts?.data;
    const overview = getData?.posts?.data?.sections?.filter(e => e.section_data?.slug === 'customers-overview')?.[0];


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getData?.posts?.data?.page_data?.title ? getData?.posts?.data?.page_data?.title   : 'Lanco Development Ltd'}</title>
                <meta name="description"  content={getData?.posts?.data?.page_data?.meta_desc ? getData?.posts?.data?.page_data?.meta_desc :''}/>
                <meta property="og:title" content={getData?.posts?.data?.page_data?.og_title ? `${getData?.posts?.data?.page_data?.og_title | 'Lanco Development Ltd'}` : ''}/>
                <meta property="og:subtitle" content={getData?.posts?.data?.page_data?.og_title ? `${getData?.posts?.data?.page_data?.og_title | 'Lanco Development Ltd'}` : ''}/>
                <meta property="og:description" content={getData?.posts?.data?.page_data?.og_desc ? getData?.posts?.data?.page_data?.og_desc :''}/>
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:image" content={banner?.page_images?.list?.filter(image => image?.banner === 'on')?.[0]?.full_path}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledComponent>
                <InnerBanner
                    title={"Customers"}
                    img={banner?.page_images?.list?.filter(image => image?.desktop === 'on')?.[0]?.full_path}
                    imgMob={banner?.page_images?.list?.filter(image => image?.mobile === 'on')?.[0]?.full_path}
                />
                <OverviewWithProps bg={'#1C1C1C'} color={'#F5F5F5'} title={overview?.section_data?.subtitle} desc={overview?.section_data?.description}/>
                <CustomerForm/>

            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
