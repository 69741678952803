import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Img} from "./Img";
import {Col, Container, Row} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import {title} from "../styles/globalStyleVars";
import {ImageParallaxNoLoad} from "./ImageParallaxNoLoad";

const InnerBanner = ({img, text, title, imgMob, uppercase}) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Update the window width when the window is resized
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <StyledInnerBanner uppercase={uppercase} className='InnerBanner'>
            {
                windowWidth > 767 ?
                    <ImageParallaxNoLoad src={img ? img : ''}/>
                    :
                    <ImageParallaxNoLoad src={imgMob ? imgMob : ''}/>
            }

            <Container>
               <Row>
                   <Col className={''}>
                       <h1>{title ? reactHtmlParser(title) : ''}</h1>
                   </Col>
               </Row>
            </Container>
        </StyledInnerBanner>
    );
};

const StyledInnerBanner = styled.section`
  padding-top: calc(550 / 1366 * 100%);
  position: relative;
    &:before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 1;
    }
  .container {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 40px;
    z-index: 2;
  }

  h1 {
    font-family: ${title};
    font-size: 60px;
    line-height: 70px;
    color: #F5F5F5;
    z-index: 2;
    text-transform: ${props => props.uppercase ?  "uppercase" : ""};
    @media(max-width: 767px){
      font-size: 30px;
      line-height: 60px;
    }
  }
  @media (min-width: 767px) {
    .title {
      width: 50%;
    }
  }

  @media (max-width: 767px) {
    //padding-top: 0;
    padding-top: calc(400 / 375 * 100%);
    .container {
      bottom: 40px;
    }
    

    h3 {
      font-size: 50px;
      line-height: 50px;
    }
  }
`;

export default React.memo(InnerBanner);
