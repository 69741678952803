import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({img, title, desc}) => {
    return (
        <StyledComponent className={'service'}>
            <div className={'img-wrapper'}>
                <img src={img} alt={'PSM Service'}/>
            </div>
            <div className={'content'}>
                <h5>{reactHtmlParser(title)}</h5>
                <p>
                    {reactHtmlParser(desc)}
                </p>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.div`
    height: 100%;
    .img-wrapper{
        position: relative;
        padding-top: calc(250 / 250 * 100%);
        overflow: hidden;
        cursor: pointer;
        img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            scale: 1;
            transition: scale 0.5s ease-in-out;
        }

        &:hover{
            img{
                scale: 1.07;
                transition: scale 0.5s ease-in-out;
            }
        }
    }
    .content{
        padding: 20px 0px;
        h5{
            font-size: 24px !important;
            line-height: 28px !important;
            color: #000;
            margin-bottom: 20px;
        }
        p{
            font-size: 16px;
            line-height: 18px;
            color: #282c34;
        }
    }
`;

export default MyComponent;
