import React, { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { Col, Container, Row } from 'react-bootstrap';
import Logo from '../Logo';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const PageLoader = ({ errorMessage }) => {


  const root = useSelector((store) => store.rootReducer);

  //--- form submit
  const success = (msg) => toast.success(msg, {
    position: 'top-right', autoClose: 4000, closeOnClick: true, progress: undefined,

  });

  const error = (msg) => toast.error(msg, {
    position: 'top-right', autoClose: 4000, closeOnClick: true, progress: undefined,

  });

  const pageKeys = ['page1', 'page2', 'page3', 'page4', 'page5', 'page6', 'page7', 'page8', 'page9', 'page10', 'page11', 'page12', 'page13', 'page14'];
  const loaderRef = useRef(null);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const logoReveal = useRef(null);
  const logoRevealImg = useRef(null);

  useEffect(() => {
    gsap.set(document.querySelector('.page-loader .logo-wrapper svg'), {
      autoAlpha: 0, // Fades out the logo
    });
  })
  useEffect(() => {

    // Simulate loading progress (increment by a small amount)
    const interval = setInterval(() => {
      setLoadingProgress((prevProgress) => {
        const newProgress = prevProgress + 1;
        if (newProgress >= 100) {
          clearInterval(interval); // Stop when progress reaches 100%
        }
        return newProgress;
      });
    }, 5); // Adjust the interval duration as needed

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  useEffect(() => {



    pageKeys.map((pageKey, index = 10) => {
      const page = root?.[pageKey];
      if (page?.loading === false) {
        gsap.to(logoReveal?.current, {
          x: `${loadingProgress}%`,
          duration: 0.1, // Adjust the duration as needed for smoother animation
          onUpdate: () => {
            // Update the inner HTML of .p1-font with the loadingProgress value
            logoReveal.current.querySelector('.p1-font').innerHTML = `${Math.floor(loadingProgress)}%`;
            const tl = gsap.timeline();
            tl.to(document.querySelector('.page-loader .logo-wrapper svg'), {
              autoAlpha: 1, // Fades out the logo
            },"-=0.5");
          },
          onComplete: () => {
            // When loadingProgress reaches 100%, start the loader exit animation
            if (loadingProgress >= 100) {
              // Create a GSAP timeline sequence
              const tl = gsap.timeline();

// Add animations to the timeline
              tl.to(loaderRef?.current, {
                delay: 0.5,
                autoAlpha: 0, // Fades out the loader
                height: 0, // Sets the height to 0
                duration: 2, // Duration of the fade-out animation
                onComplete: () => {
                  // Optionally, you can perform some action when the animation is complete.
                },
              }) // Start this animation 0.1 seconds before the previous one ends
                .to(document.querySelector('.page-loader .logo-wrapper svg'), {
                  autoAlpha: 0, // Fades out the logo
                  duration: 1,
                }, '-=2.4')
                // Start this animation 0.1 seconds before the previous one ends
                .to(document.querySelector('.page-loader .logo-wrapper svg'), {
                  autoAlpha: 0, // Fades out the logo
                  duration: 1,
                }, '-=1.4')
                .to(document.querySelector('.page-loader .p1-font'), {
                  autoAlpha: 0, // Fades out the text
                  duration: 1,
                }, '-=2.6'); // Start this animation 0.4 seconds before the previous one ends
              tl.play();

            }
          },
        })
        // gsap.to(document.querySelector('.page-loader .logo-wrapper svg'), {
        //   autoAlpha: 1, // Fades out the logo
        // },"-=0.5");
        ;
      }

    });
  }, [pageKeys]);

  return (
    <div className='page-loader' ref={loaderRef}>
      <Container>
        <Row>

          <Col md={12} className={'text-center log-col'}>
            <div className='logo-wrapper'>
              <div className='cover' ref={logoReveal}>
                <p className='p1-font'>0%</p>
              </div>

              {
                error ?
                  <Logo />
                  :
                  <>
                    <svg ref={logoRevealImg} classNam={'preloader-logo'} xmlns="http://www.w3.org/2000/svg" width="199.239" height="25" viewBox="0 0 199.239 25">
                      <g id="Group_21058" data-name="Group 21058" transform="translate(14936.767 632)">
                        <path id="Path_8647" data-name="Path 8647" d="M7.385,618.657v6.388H18.572v4.489H7.385v8.805H1.791V614.168H20.057v4.489Z" transform="translate(-14938.558 -1245.754)" fill="#fff"/>
                        <path id="Path_8648" data-name="Path 8648" d="M113.331,635.4a12.042,12.042,0,0,1-4.731-4.472,12.856,12.856,0,0,1,0-12.811,12.045,12.045,0,0,1,4.731-4.472,14.959,14.959,0,0,1,13.553,0,12.328,12.328,0,0,1,0,21.754,14.961,14.961,0,0,1-13.553,0m10.653-4.126a7.065,7.065,0,0,0,2.711-2.746,8.644,8.644,0,0,0,0-8.011,7.063,7.063,0,0,0-2.711-2.745,8.092,8.092,0,0,0-7.735,0,7.059,7.059,0,0,0-2.711,2.745,8.642,8.642,0,0,0,0,8.011,7.061,7.061,0,0,0,2.711,2.746,8.089,8.089,0,0,0,7.735,0" transform="translate(-15023.354 -1244.024)" fill="#fff"/>
                        <path id="Path_8649" data-name="Path 8649" d="M281.013,638.339l-4.662-6.733h-5.145v6.733h-5.594V614.168h10.463a13.436,13.436,0,0,1,5.577,1.07,8,8,0,0,1,4.92,7.7,7.873,7.873,0,0,1-4.972,7.631l5.421,7.769ZM279.6,619.814a5.876,5.876,0,0,0-3.833-1.088h-4.558v8.425h4.558a5.809,5.809,0,0,0,3.833-1.105,4.357,4.357,0,0,0,0-6.233" transform="translate(-15151.413 -1245.754)" fill="#fff"/>
                        <path id="Path_8650" data-name="Path 8650" d="M418.981,638.34l-.035-14.5-7.113,11.947h-2.521l-7.079-11.637V638.34h-5.249V614.168h4.627l9.047,15.021,8.909-15.021h4.592l.069,24.171Z" transform="translate(-15257.405 -1245.754)" fill="#fff"/>
                        <path id="Path_8651" data-name="Path 8651" d="M566.257,635.4a12.041,12.041,0,0,1-4.731-4.472,12.856,12.856,0,0,1,0-12.811,12.045,12.045,0,0,1,4.731-4.472,14.958,14.958,0,0,1,13.553,0,12.328,12.328,0,0,1,0,21.754,14.96,14.96,0,0,1-13.553,0m10.653-4.126a7.066,7.066,0,0,0,2.71-2.746,8.643,8.643,0,0,0,0-8.011,7.064,7.064,0,0,0-2.71-2.745,8.092,8.092,0,0,0-7.735,0,7.061,7.061,0,0,0-2.711,2.745,8.644,8.644,0,0,0,0,8.011,7.063,7.063,0,0,0,2.711,2.746,8.089,8.089,0,0,0,7.735,0" transform="translate(-15388.779 -1244.024)" fill="#fff"/>
                        <path id="Path_8652" data-name="Path 8652" d="M740.706,614.169V638.34h-4.593l-12.051-14.675V638.34h-5.525V614.169h4.627l12.017,14.675V614.169Z" transform="translate(-15516.839 -1245.754)" fill="#fff"/>
                        <rect id="Rectangle_482" data-name="Rectangle 482" width="5.594" height="24.171" transform="translate(-14770.4 -631.585)" fill="#fff"/>
                        <path id="Path_8653" data-name="Path 8653" d="M920.126,635.417a11.979,11.979,0,0,1-4.7-4.454,12.986,12.986,0,0,1,0-12.88,11.98,11.98,0,0,1,4.7-4.454,14.984,14.984,0,0,1,12.4-.5,11.389,11.389,0,0,1,4.265,3.177L933.2,619.62a7.723,7.723,0,0,0-6.077-2.831,8.07,8.07,0,0,0-4.005.984,7,7,0,0,0-2.745,2.745,8.645,8.645,0,0,0,0,8.011,7,7,0,0,0,2.745,2.745,8.072,8.072,0,0,0,4.005.984,7.684,7.684,0,0,0,6.077-2.866l3.592,3.315a11.2,11.2,0,0,1-4.282,3.212,14.224,14.224,0,0,1-5.7,1.1,13.88,13.88,0,0,1-6.682-1.606" transform="translate(-15674.315 -1244.023)" fill="#fff"/>
                      </g>
                    </svg>

                  </>

              }
            </div>

          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PageLoader;
