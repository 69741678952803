import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Title from "../Title";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {
    return (
        <StyledComponent className={'pt-150 pb-150'}>
            <Container>
                <Title text={data?.section_data?.title} margin={'0 0 40px'}/>
                <div className={'img-wrapper'}>
                    <Img src={data?.images?.list?.[0]?.full_path}/>
                </div>
                <p>{reactHtmlParser(data?.section_data?.description)}</p>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #f5f5f5;
    .img-wrapper{
      position: relative;
      padding-top: calc(550 / 1366 * 100%);
    }
  p{
    margin-top: 40px;
  }
`;

export default MyComponent;
