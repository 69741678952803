import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Col, Container, Form, Row} from "react-bootstrap";

import Select, {components} from "react-select";
import Title from "../Title";
// import {bgColor_2, text} from "../../styles/globalStyleVars";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import Subtitle from "../SubTitle";
import Button from "../Button";
import {Controller, useForm} from "react-hook-form";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {postCustomerForm} from "../../api/redux/customer";
import {postBuyerForm} from "../../api/redux/buyer";

const MyComponent = ({title}) => {

    const type = [
        {value: 'option1', label: 'Option 1'},
        {value: 'option2', label: 'Option 2'},
    ]

    const customStyles = {
        dropdownIndicator: (base, state) => ({
            ...base,
            transition: "all .2s ease",
            transform: state.selectProps.menuIsOpen && "rotate(180deg)"
        }),
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? `#000000` : '#535353',
            backgroundColor: state.isSelected ? `rgba(232, 231, 231, 0.6)` : '#FFF',
            margin: 0,
            fontSize: 14,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 10,
            fontWeight: state.isSelected ? 700 : 400,
            "&:hover": {
                backgroundColor: `#000000`,
                color: '#FFF',
                cursor: 'pointer'
            },

        }), menu: (provided, state) => ({
            ...provided,
            color: '#FFF',
            backgroundColor: state.isSelected ? `#000000` : 'rgba(255,255,255,0)',
            margin: 0,
            borderRadius: 5,
            fontSize: 14,
            // width: 200,
        }), menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#191818' : '#FFF',
            borderRadius: 0,
            fontWeight: '400',
            color: '#FFF',
            fontSize: 14,
        }),

    };

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <CaretDownIcon/>
            </components.DropdownIndicator>
        );
    };



    const CaretDownIcon = () => {
        return <svg xmlns="http://www.w3.org/2000/svg" width="13.414" height="7.414" viewBox="0 0 13.414 7.414">
            <g id="Group_15659" data-name="Group 15659" transform="translate(1479.207 -192.793) rotate(90)">
                <line id="Line_3883" data-name="Line 3883" x2="6" y2="6" transform="translate(193.5 1466.5)" fill="none" stroke="#231f20" strokeLinecap="round" stroke-width="1"/>
                <line id="Line_3884" data-name="Line 3884" y1="6" x2="6" transform="translate(193.5 1472.5)" fill="none" stroke="#231f20" strokeLinecap="round" stroke-width="1"/>
            </g>
        </svg>
            ;
    };


    //offset left container

    const [offset, setOffset] = useState()
    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft)
    }, [])



    // form submit start

    const [selectedOption, setSelectedOption] = useState(null);
    const dispatch = useDispatch();
    const responseData = useSelector(state => state.customer);

    const {register, handleSubmit ,formState,reset,control} = useForm({mode: 'all'});


    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });


    const onSubmit = async (e) => {
        try {
            let api_services = apiEndPoints.BUYER_FORM;

            var formData = new FormData();
            formData.append('property_location', e?.property_location);
            formData.append('preferred_size', e?.preferred_size);
            formData.append('parking_required', e?.parking_required);
            formData.append('expected_handover', e?.expected_handover);
            formData.append('preferred_facing', e?.preferred_facing);
            formData.append('preferred_floor', e?.preferred_floor);
            formData.append('no_of_bedroom', e?.no_of_bedroom);
            formData.append('no_of_bathroom', e?.no_of_bathroom);
            formData.append('name', e?.name);
            formData.append('profession', e?.profession);
            formData.append('number',e?.number);
            formData.append('email', e?.email);
            formData.append('form_id', 'customers-form');

            const response = await dispatch(postCustomerForm([api_services, formData]));

            if (response && response.payload?.status === 1) {
                success(response.payload?.message);
                reset(); // Reset form fields
            } else if (response) {
                error(response?.payload?.response?.data?.message);
                reset(); // Reset form fields
            } else {
                error('Failed to submit form. Please try again later.');
            }
        }catch (error) {
            error('Failed to submit form. Please try again later.');
        }
    };
    let count = 0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if (count > 0) {
            toast.error('please fill out the correct inputs');
        }
        count = 0;
    };


    //Form Submit end

    return (
        <StyledComponent>
            <Container  className={'land'}>
                <div className={'land-form pt-120 pb-120'}>
                    <Form className={'form'} onSubmit={handleSubmit(onSubmit, onError)}>
                        <Row>
                            <Col className={'contact-map__left form-gap'} md={6}>
                                <div className="contact-map__form">
                                    <div className="contact-map__form-title">
                                        <h4>A. Your Valued Interest</h4>
                                    </div>
                                    <div className="form-group">
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Control
                                                className={formState?.errors?.property_location?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                {...register("property_location",{
                                                    required: 'Property location is required',
                                                    pattern: {
                                                        value: /([A-Z])\w+/,
                                                        message: 'Property location must contain only letters',
                                                    },
                                                })}
                                                type="text" placeholder="Preferred location *"
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="form-group">
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Control
                                                className={formState?.errors?.preferred_size?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                {...register("preferred_size",{
                                                    required: 'Property size is required',
                                                    pattern: {
                                                        pattern:{
                                                            value: /^01[0-9]{9}$/,
                                                            message:'please enter a valid 11 digit phone number'
                                                        },
                                                        message: 'Property size must contain only Numbers',
                                                    },
                                                })}
                                                type="text" placeholder="Preferred size *"
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="form-group">
                                        <Form.Group controlId="formBasicPhone">
                                            <Form.Control
                                                className={formState?.errors?.preferred_location?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                {...register("parking_required",{
                                                    // required: 'Property type is required',
                                                    // pattern: {
                                                    //     // value: /([A-Z])\w+/,
                                                    //     message: 'Property type must contain only letters',
                                                    // },
                                                })}
                                                type="text" placeholder="Parking required"
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="form-group">
                                        <Form.Group controlId="formBasicPhone">
                                            <Form.Control
                                                className={formState?.errors?.expected_handover?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                {...register("expected_handover",{
                                                    // required: 'Property type is required',
                                                    // pattern: {
                                                    //     // value: /([A-Z])\w+/,
                                                    //     message: 'Property type must contain only letters',
                                                    // },
                                                })}
                                                type="text" placeholder="Expected handover"
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                            </Col>
                            <Col className={'contact-map__right contact-map__form'} md={6}>
                                <div className="contact-map__form">
                                    <div className="contact-map__form-title">
                                        <h4>C. Contact Information</h4>
                                    </div>
                                    <div className="form-group">
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Control
                                                className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                {...register("name",{
                                                    required: 'Name is required',
                                                    pattern: {
                                                        value: /([A-Z])\w+/,
                                                        message: 'Name must contain only letters',
                                                    },
                                                })}
                                                type="text" placeholder="Name *"
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="form-group">
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Control
                                                className={formState?.errors?.profession?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                {...register("profession",{
                                                    // required: 'Profession is required',
                                                    // pattern: {
                                                    //     value: /([A-Z])\w+/,
                                                    //     message: 'Profession must contain only letters',
                                                    // },
                                                })}
                                                type="text" placeholder="Profession"
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="form-group">
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Control
                                                className={formState?.errors?.number?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                {...register("number",{
                                                    required: 'Number is required',
                                                    pattern:{
                                                        value: /^01[0-9]{9}$/,
                                                        message:'please enter a valid 11 digit phone number'
                                                    }
                                                })}
                                                type="number" placeholder="Contact Number *"
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="form-group">
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Control
                                                className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                {...register("email",{
                                                    required:{
                                                        value:true,
                                                        message:'please enter your email'
                                                    },
                                                    pattern:{
                                                        value:/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                        message:'please enter a valid email address'
                                                    }
                                                })}
                                                type="email" placeholder="Email *"
                                            />
                                        </Form.Group>
                                    </div>

                                    <div className={"dc-btn"} onClick={handleSubmit(onSubmit,onError)}>
                                        <Button text={'Submit'} background={'transparent'} color={'#1C1C1C'}
                                                hoverBackground={'#535353'}/>

                                    </div>

                                </div>

                            </Col>
                            <Col className={'contact-map__right contact-map__form'} md={6}>
                                <div className="contact-map__form">
                                    <div className="contact-map__form-title">
                                        <h4>B. Your Preferences</h4>
                                    </div>
                                    <div className="form-group">
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Control
                                                className={formState?.errors?.preferred_facing?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                {...register("preferred_facing",{
                                                    // required: 'Property type is required',
                                                    // pattern: {
                                                    //     // value: /([A-Z])\w+/,
                                                    //     message: 'Property type must contain only letters',
                                                    // },
                                                })}
                                                type="text" placeholder="Preferred facing"
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="form-group">
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Control
                                                className={formState?.errors?.preferred_floor?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                {...register("preferred_floor",{
                                                    // required: 'Profession is required',
                                                    // pattern: {
                                                    //     value: /([A-Z])\w+/,
                                                    //     message: 'Profession must contain only letters',
                                                    // },
                                                })}
                                                type="text" placeholder="Preferred floor"
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="form-group">
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Control
                                                className={formState?.errors?.no_of_bedroom?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                {...register("no_of_bedroom",{
                                                    required: 'Number is required',
                                                    pattern:{
                                                        value: /[0-9]$/,
                                                        message:'please enter a valid 11 digit phone number'
                                                    }
                                                })}
                                                type="number" placeholder="No. of bedrooms *"
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="form-group">
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Control
                                                className={formState?.errors?.no_of_bathroom?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                {...register("no_of_bathroom",{
                                                    required: 'Number is required',
                                                    pattern:{
                                                        value: /[0-9]$/,
                                                        message:'please enter a valid 11 digit phone number'
                                                    }
                                                })}
                                                type="text" placeholder="No. of bathrooms *"
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Container>
        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  background-color: #F2F2F2;


  .land{
      //padding-left: ${props => props.offset}px;
    padding-left: 100px;
    padding-right: 100px;
    @media(max-width: 767px){
      padding-right: 15px;
      padding-left: 15px;
    }
    .land-form{
      position: relative;
      z-index: 999;
      @media(max-width: 767px){
        padding-right: 15px;
        padding-left: 15px;
      }
    }
    @media(max-width: 767px){
      padding: 0;
    }
  }

  .fade-up {
    transform: translateY(0px) !important;
    opacity: 1 !important;
    visibility: visible !important;
  }



  .contact-map__form-title{
    margin-bottom: 40px;
    @media(max-width: 767px){
      margin-bottom: 30px;
    }

    h4{
      font-size: 24px;
      line-height: 28px;
      font-weight: 400;
      color: #000000;
    }
  }

  @media (min-width: 1550px) {
    .subtitle{
      h2{
        font-size: 24px;
      }
    }
  }

  .css-2b097c-container {
    margin-bottom: 40px;
  }

    .dc-btn{
        width: max-content !important;
    }

`
export default React.memo(MyComponent);