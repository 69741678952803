import React, {useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import styled from "styled-components";
import CompletedProject from "../../components/home/CompletedProject";
import Completed from "../../components/home/Completed";
import {fetchHomeData} from "../../api/redux/home";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import Loadable from 'react-loadable';
import Discover from "../../components/Discover";
import FindProperty from "../../components/home/FindProperty";
import {useInView } from 'react-intersection-observer';
import HomeBanner from "../../components/home/HomeBanner";
import AboutLanco from "../../components/home/AboutLanco";
import BusinessUnitsSlider from "../../components/BusinessUnitsSlider";
import {Helmet, HelmetProvider} from "react-helmet-async";
import BannerSliderNew from "../../components/home/BannerSliderNew";
import CompletedCTA from "../../components/home/CompletedCTA";
import CompletedBox from "../../components/home/CompletedBox";
import BlogSlider from "../../components/home/BlogSlider";




const LoadableFeatured = Loadable({
    loader: () => import("../../components/home/OngoingProject"),
    loading() {
        return <div>Loading...</div>
    }
});


const LoadableNews = Loadable({
    loader: () => import("../../components/home/NewsEv" +
    "ents"),
    loading() {
        return <div>Loading...</div>
    }
});




const Home = () => {

    const [ref, inView] = useInView({
        triggerOnce: true, // Ensures it triggers only once
        rootMargin: '0px 0px 100px 0px', // Adjust this margin as needed
    });


    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.HOME;

        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'home',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
        }

        dispath(fetchHomeData([api_url , api_params]))
    }, [])


    //Refactor Data

    const getData = useSelector((state) => state.home);



    const homebanner = getData?.posts?.sliderProducts?.list;
    const about = getData?.posts?.data?.sections?.filter(e => e.section_data?.slug === 'about-lanco-development')?.[0];
    const dreams = getData?.posts?.data?.sections?.filter(e => e.section_data?.slug === 'customized-dreams')?.[0];
    const property = getData?.posts?.location_list;
    const completed = getData?.posts?.data?.sections?.filter(e => e.section_data?.slug === 'completed-projects')?.[0];
    const completedProject =getData?.posts?.completedProducts?.list;
    const ongoingProject = getData?.posts?.ongoingProducts?.list;
    const ongoing = getData?.posts?.data?.sections?.filter(e => e.section_data?.slug === 'featured-projects')?.[0];
    const discover = getData?.posts?.data?.sections?.filter(e => e.section_data?.slug === 'discover')?.[0];
    const featureNews = getData?.posts?.featuredNews;
    const business = getData?.posts?.data?.sections?.filter(e => e.section_data?.slug === 'business-units')?.[0];
    const businessList = getData?.posts?.businessUnits;



    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getData?.posts?.data?.page_data?.title ? getData?.posts?.data?.page_data?.title   : 'Lanco Development Ltd'}</title>
                <meta name="description"  content={getData?.posts?.data?.page_data?.meta_desc ? getData?.posts?.data?.page_data?.meta_desc :''}/>
                <meta property="og:title" content={getData?.posts?.data?.page_data?.og_title ? `${getData?.posts?.data?.page_data?.og_title | 'Lanco Development Ltd'}` : ''}/>
                <meta property="og:subtitle" content={getData?.posts?.data?.page_data?.og_title ? `${getData?.posts?.data?.page_data?.og_title | 'Lanco Development Ltd'}` : ''}/>
                <meta property="og:description" content={getData?.posts?.data?.page_data?.og_desc ? getData?.posts?.data?.page_data?.og_desc :''}/>
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:image" content={'/images/static/logo.svg'}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>
            <StyledSection ref={ref}>

                <BannerSliderNew data={homebanner}/>
                <FindProperty data={property}/>
                <AboutLanco data={about}/>
                <CompletedCTA data={dreams}/>
                <CompletedBox data={completedProject}/>
                <LoadableFeatured data={ongoing} list={ongoingProject}/>
                <BlogSlider data={featureNews}/>
                <BusinessUnitsSlider data={business} list={businessList}/>

            </StyledSection>
        </HelmetProvider>

    );
};

const StyledSection = styled.section`
    
`;

export default Home;
