import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    globalLoader: true,
}

const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        loading: (state) => {
            state.globalLoader = false
        },
    },
    extraReducers: {
        ['fetchAboutData/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchBusinessPosts/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchBusinessPostDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchBuyerData/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['postBuyerForm/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchCareerPosts/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchCareerPostDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['postCareerForm/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchContactData/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchCustomerData/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['postCustomerForm/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchDirectorData/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchHomeData/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['postForm/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchLandData/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['postLandForm/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchManagementData/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchMissionData/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchNewsPosts/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchNewsPostDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchProjectPosts/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchProjectPostList/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchProjectPostDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchStoryData/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchSocial/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
    }
})


export default globalSlice.reducer
export const {loading} = globalSlice.actions
