import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import {title} from "../../styles/globalStyleVars";


const MyComponent = ({data}) => {



    const office = data?.posts?.list?.filter(e => e?.data?.slug === 'office-address')?.[0];
    const sales = data?.posts?.list?.filter(e => e?.data?.slug === 'sales')?.[0];
    const land = data?.posts?.list?.filter(e => e?.data?.slug === 'for-land')?.[0];


    return (
        <StyledComponent>
            <Container className={'address'}>
                <Row>
                    <Col md={3} className={'address__title'}>
                        <h4 className={'split-up'}>{data?.section_data?.subtitle}</h4>
                    </Col>
                    <Col md={3}>
                        <div className={'address__info__single'}>
                            <h6 className={'split-up'}>{office?.data?.title}</h6>
                            {
                                reactHtmlParser(office?.data?.description)
                            }
                            {
                                reactHtmlParser(office?.data?.subtitle)
                            }
                            {
                                reactHtmlParser(office?.data?.short_desc)
                            }
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className={'address__info__single'}>
                            <h6 className={'split-up'}>{sales?.data?.title}</h6>
                            {
                                reactHtmlParser(sales?.data?.subtitle)
                            }
                            {
                                reactHtmlParser(sales?.data?.short_desc)
                            }
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className={'address__info__single'}>
                            <h6 className={'split-up'}>{land?.data?.title}</h6>
                            {
                                reactHtmlParser(land?.data?.subtitle)
                            }
                            {
                                reactHtmlParser(land?.data?.short_desc)
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  padding-top: 120px;
  padding-bottom: 80px;
  background-color: #000000;
  .address{
    &__title{
      h4{
        font-family: ${title};
        font-size: 40px;
        line-height: 48px;
        font-weight: 500;
        color: #fff;
      }
    }
    .address__info__single{
      margin-right: 30px;
      h6{
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: #9B9B9B;
        margin-bottom: 15px;
      }
      p{
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        a{
          color: #C9C9C9;
        }
      }
      &:nth-child(2n){
        margin-right: 95px;
      }
      &:last-child{
        margin-right: 0;
      }
    }
    @media(max-width: 767px){
      display: block;
      &__single{
        margin-top: 40px;
      }
    }
  }
`;

export default React.memo(MyComponent);
