import {useLocation} from 'react-router-dom';
import Error from './pages/404';
import GlobalStyle from "./styles/globalStyle";
import {ImageReveal} from "./components/animations/ImageReveal";
import {Parallax, ParallaxImg} from "./components/animations/Parallax";
import {gsap} from "gsap";
import {ScrollSmoother} from "gsap/ScrollSmoother";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import React, {lazy, Suspense, useState} from "react";


// page imports
import {useEffect, useLayoutEffect, useRef} from "react";
import Menu from "./components/Menu";
import Call from "./components/Call";
import Calculator from "./components/Calculator";
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
import {useSelector} from "react-redux";
import {Loading} from "./components/Loading";
import FooterNw from "./components/FooterNw";
import Messanger from "./components/Messanger";
import NoticePopUp from "./components/NoticePopUp";
import Router from "./router/Router";
import PageLoader from './components/preload/PageLoader';
gsap.registerPlugin(ScrollTrigger, ScrollSmoother);




function App() {
    const store = useSelector((store) => store);
    const location = useLocation();



    // smooth scroll init
    const el = useRef();
    useLayoutEffect(() => {
        let smoother = ScrollSmoother.create({
            smooth: window.innerWidth > 767 ? 2 : '', // how long (in seconds) it takes to "catch up" to the native scroll position
            effects: window.innerWidth > 767 ? true : false, // looks for data-speed and data-lag attributes on elements
            // speed: 2
        });
        return () => {
            smoother.kill();
        };
    }, [location.pathname]);


    // route change scroll top & page class
    useEffect(() => {
        if (location.pathname === "/") {
            document.body.classList.add("in-home-page");
        } else {
            document.body.classList.remove("in-home-page");
        }
        window.scroll(0, 0)
    }, [location.pathname]);




    // animation functions
    ImageReveal()
    Parallax()
    ParallaxImg()
    // SplitUp()
    // PageTransition()



    //canonical link generate
    useEffect(() => {
        // Get the current URL from the router's full path
        const currentUrl = window.location.origin + location.pathname + location.search;

        // Set the canonical link dynamically
        const link = document.createElement('link');
        link.rel = 'canonical';
        link.href = currentUrl;
        document.head.appendChild(link);

        // Clean up the link when the component unmounts
        return () => {
            document.head.removeChild(link);
        };
    }, [location.pathname, location.search]);



    //subscriber form

    const [showNotice, setShowNotice] = useState(false);

    useEffect(() => {
        // Check if the 'visited' key is in sessionStorage
        const visited = sessionStorage.getItem('visited');

        // If 'visited' key is not present, it's the first visit
        if (!visited) {
            // Set the 'visited' key in sessionStorage
            sessionStorage.setItem('visited', 'true');
            // Show the notice pop-up
            setShowNotice(true);
        }
    }, []);

    const closeNotice = () => {
        // Handle the close action if needed
        setShowNotice(false);
    };



    return (
        <>
            {store?.project?.loading && <Loading/> }
            <GlobalStyle/>
            <ToastContainer position="top-right" autoClose={4000} closeOnClick hideProgressBar={true}/>
            <Menu/>
            {
                location.pathname === '/' &&
                <PageLoader />
            }
            <div className="App" ref={el} id="smooth-wrapper">
                <div id="smooth-content">
                    <Router/>
                    <FooterNw/>
                </div>
            </div>
            <Messanger/>
            <Call/>
            {
                location.pathname === '/lanco-easy-term' ?
                    <Calculator/>
                    : ''
            }
            {location.pathname === '/' &&
                <NoticePopUp show={showNotice} onClose={closeNotice} />
            }
        </>


    );
}

export default App;
