import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {Img} from "../ParallaxBG";
import Title from "../Title";
import {Col, Container, Row} from "react-bootstrap";
import Button from "../Button";
import {title} from "../../styles/globalStyleVars";
import {ImgInnerBanner} from "../ImgInnerBanner";
import reactHtmlParser from "react-html-parser";


const Cta = ({data}) => {

    return (

        <StyledCTA>
            <div className="image-wrapper">
                <ImgInnerBanner src={data?.images?.list?.[0]?.full_path}/>
                <Container>
                    <Row>
                        <Col>
                            <h2>{reactHtmlParser(data?.section_data?.subtitle)}</h2>
                        </Col>
                    </Row>
                </Container>
            </div>
        </StyledCTA>

    )
};


const StyledCTA = styled.div`
  position: relative;
  overflow: hidden;
  .image-wrapper {
    padding-top: 257px;
    padding-bottom: 150px;
    //padding-top: calc(606 / 1366 * 100%);
    position: relative;
    overflow: hidden;
    z-index: 3;
    @media(min-width: 1920px){
      padding-top: 250px;
      padding-bottom: 250px;
    }
  }
  
  h2{
    font-size: 60px;
    line-height: 80px;
    font-family: ${title};
    font-weight: 300;
    color: #F5F5F5;
    @media(max-width: 767px){
      font-size: 30px;
      line-height: 32px;
    }
  }


  @media (max-width: 767px) {
    .image-wrapper {
      padding-top: 191px;
      padding-bottom: 191px;
    }

  }
`;

export default React.memo(Cta);














