import React, {useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import {text} from "../../styles/globalStyleVars";
import {BsChevronDown} from "react-icons/bs";
import Select from "react-select";
import Button from "../Button";


const MyComponent = ({data}) => {

    //dropdown -from
    const [selectType, setSelectType] = useState('')
    const selectTypeInputRef = useRef(null); // Add this line to define the ref

    const handleType = (e) => {
        setSelectType(e);
        // selectLocationInputRef?.current?.select?.clearValue();

    }

    const customStyles = {
        dropdownIndicator: (base, state) => ({
            ...base,
            transition: "all .2s ease",
            transform: state.selectProps.menuIsOpen && "rotate(180deg)",
            border: state.isFocused ? 0 : 0,
            boxShadow: "none"
        }),
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? '#221F1F' : '#221F1F',
            backgroundColor: state.isSelected ? 'transparent' : '#fff',
            marginTop: 10,
            fontSize: 14,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 10,
            outline: 'none',
            fontWeight: state.isSelected ? 700 : 400,
            "&:hover": {
                backgroundColor: `${text}`,
                color: '#FFF',
                cursor: 'pointer'
            },

        }), menu: (provided, state) => ({
            ...provided,
            color: '#FFF',
            backgroundColor: state.isSelected ? `${text}` : 'rgba(255,255,255)',
            margin: 0,
            borderRadius: 5,
            fontSize: 14,
            zIndex: 10,
            outline: 'none'
            // width: 200,
        }), menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#000' : '#FFF',
            borderRadius: 5,
            fontWeight: '400',
            color: '#FFF',
            fontSize: 14,
            outline: 'none'
        }),
        control: (provided) => ({
            ...provided,
            borderRadius: 23, // Adjust the value as needed
            outline: "none",
            // Other control styles if needed
        }),
    };

    const options = data && data?.map(item => ({
        value: item.location,
        label: item.location,
    })).filter((item, index, self) =>
        index === self.findIndex((t) => t.value === item.value)
    );

    console.log('location', options)

    // eslint-disable-next-line no-undef
    const DropdownIndicator = (props) => {
        return (
            <div {...props}>
                <BsChevronDown style={{ transform: props.selectProps.menuIsOpen ? "rotate(180deg)" : "rotate(0deg)" }}/>
            </div>
        );
    };

    const CaretDownIcon = () => {
        return <svg xmlns="http://www.w3.org/2000/svg" width="13.414" height="7.414" viewBox="0 0 13.414 7.414">
            <g id="Group_15659" data-name="Group 15659" transform="translate(1479.207 -192.793) rotate(90)">
                <line id="Line_3883" data-name="Line 3883" x2="6" y2="6" transform="translate(193.5 1466.5)" fill="none"
                      stroke="#fff" strokeLinecap="round" stroke-width="1"/>
                <line id="Line_3884" data-name="Line 3884" y1="6" x2="6" transform="translate(193.5 1472.5)" fill="none"
                      stroke="#fff" strokeLinecap="round" stroke-width="1"/>
            </g>
        </svg>
            ;
    };
    return (
        <StyledComponent>
            <Container className={'find'}>
                <Row>
                    <Col md={4} className={''}>
                        <div className={'find__left'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                <g id="Group_24293" data-name="Group 24293" transform="translate(2038 791)">
                                    <g id="Group_24292" data-name="Group 24292" transform="translate(2 4)">
                                        <g id="Group_24171" data-name="Group 24171" transform="translate(-2033 -788)">
                                            <rect id="Rectangle_6219" data-name="Rectangle 6219" width="16" height="16" fill="#fff" opacity="0"/>
                                            <g id="find_a_property-01" data-name="find a property-01" transform="translate(1.6 1.6)">
                                                <path id="Path_9574" data-name="Path 9574" d="M126.947,31a4.007,4.007,0,1,0,4.007,4.006A4.007,4.007,0,0,0,126.947,31Zm0,7.265a3.258,3.258,0,1,1,3.258-3.258,3.258,3.258,0,0,1-3.258,3.258Z" transform="translate(-118.233 -29.813)" fill="#f5f5f5"/>
                                                <rect id="Rectangle_6090" data-name="Rectangle 6090" width="1.292" height="6.024" rx="0.646" transform="translate(5.409 7.626) rotate(45)" fill="#f5f5f5"/>
                                                <rect id="Rectangle_6091" data-name="Rectangle 6091" width="1.44" height="1.44" transform="translate(7.205 3.397)" fill="#fff"/>
                                                <rect id="Rectangle_6092" data-name="Rectangle 6092" width="1.44" height="1.44" transform="translate(9.115 3.397)" fill="#fff"/>
                                                <rect id="Rectangle_6093" data-name="Rectangle 6093" width="1.44" height="1.44" transform="translate(7.205 5.349)" fill="#fff"/>
                                                <rect id="Rectangle_6094" data-name="Rectangle 6094" width="1.44" height="1.44" transform="translate(9.115 5.349)" fill="#fff"/>
                                                <path id="Path_9575" data-name="Path 9575" d="M4.535,5.2A4.2,4.2,0,0,1,9.53,1.081l.036-.064L7.741,0,0,4.535H3.206L3.193,9.579c.021-.028,1.085-1.091,2.049-2.053A4.173,4.173,0,0,1,4.535,5.2Z" fill="#fff"/>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Ellipse_640" data-name="Ellipse 640" transform="translate(-2038 -791)" fill="none" stroke="#fff" stroke-width="1">
                                        <circle cx="15" cy="15" r="15" stroke="none"/>
                                        <circle cx="15" cy="15" r="14.5" fill="none"/>
                                    </g>
                                </g>
                            </svg>
                            <h5>
                                Find The Property
                            </h5>
                        </div>
                    </Col>
                    <Col md={8}>
                        <Form>
                            <div className={'d-flex find__right'}>
                                <Form.Group className="form-control find-form">
                                    <Select
                                        classNamePrefix="filter"
                                        isSearchable={false}
                                        // ref={selectTypeInputRef}
                                        options={options}
                                        onChange={e => handleType(e?.value)}
                                        placeholder='Project Location'
                                        styles={customStyles}
                                        components={{DropdownIndicator}}
                                    />
                                </Form.Group>
                                <Button text={'Search Property'} background={'#1C1C1C'} hoverBackground={'#535353'} borderColor={"rgb(255 255 255 / 50%)"}
                                        src={`/project?location=${selectType}`}
                                />
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #1C1C1C;
  display: flex;
  align-items: center;

  .find {
    padding-top: 35px;
    padding-bottom: 35px;
    &__left {
      display: flex !important;
      align-items: center;
      //gap: 15px;

      h5 {
        font-family: Roboto;
        font-size: 16px;
        line-height: 32px;
        font-weight: 400;
        color: #FFFFFF;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 15px;
      }
    }

    &__right {
      justify-content: right;
      @media(max-width: 768px) {
        flex-direction: column;
        width: 100%;
        .dc-btn {
          width: 100% !important;
        }
      }

      .find-form {
        background-color: unset;
        border: 0;
        padding: 0;
        height: auto;
        justify-content: center;
        margin-right: 20px;
        @media(max-width: 992px) {
          margin-bottom: 20px;
          margin-right: 0 !important;
        }
      }

      .form-control {
        width: unset;
      }

      .css-2b097c-container {
        width: 370px;
        margin-right: 30px;
        border-radius: 23px;

        .filter__control {
          background-color: transparent;
          border: 1px solid rgb(255 255 255 / 50%);
          padding: 12px 30px;
          box-shadow: none;
          position: relative;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          @media(max-width: 767px) {
            background: transparent;
          }
          &:after {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            right: 0;
            top: 100%;
            margin: auto;
            background-color: #535353;
            transition: all .5s ease;
            border-radius: 23px;
          }
          &:hover {
            &:after {
              top: 0;
            }
          }
          }
          @media(max-width: 767px){
            background-color: transparent;
            width: 100%;
          }
        }

        .filter__value-container {
          padding: 0;
        }

        .filter__placeholder {
          margin: 0;
          font-size: 16px;
          line-height: 20;
          font-weight: 400;
          color: #fff;
          position: absolute;
          z-index: 999;
        }

        .filter__indicators {
          position: relative;
          z-index: 999;
          svg {
            position: relative;
            top: -2px;
            color: #fff;
          }
        }

        .filter__menu {
          margin-top: 10px;
        }

        .filter__indicator-separator {
          display: none;
        }

        .filter__indicator {
          position: relative;
        }

        .filter__single-value {
          color: #fff;
          z-index: 999;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .find {
      &__left {
        margin-bottom: 30px;
      }

      &__right {
        //justify-content: space-between;
        justify-content: left;
        .find-form {
          margin-right: 18px;
        }
        .css-2b097c-container {
          margin-right: 0;
        }
      }
    }
  }
`;

export default React.memo(MyComponent);
