import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Img} from "../Img";
import {Col, Container, Row} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import {ImgInnerBanner} from "../ImgInnerBanner";
import {ImageParallax} from "../ImageParallax";

const InnerBanner = ({img, text, title, imgMob, uppercase}) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <StyledInnerBanner className='InnerBanner'>
            <div className={'image-wrapper'}>
                <Img src={img ? img : ''}/>
                <Container className={''}>
                    <Row>
                        <Col md={10} className={'split-up'}>
                            <h5>{title ? reactHtmlParser(title) : 'Our Approach'}</h5>
                            <p>
                                {text ?  reactHtmlParser(text) : 'LANCO DEVELOPMENTS LTD. (ldl) is a pioneer real estate company in Bangladesh. We are a growing & upcoming construction company.  We started our journey through construction of residential, commercial and industrial projects with the focus on returning maximum profits both in financial and non-financial aspects for our clients.' +
                                    'LANCO DEVELOPMENTS LTD. (ldl) is a pioneer real estate company in Bangladesh. We are a growing & upcoming construction company.  We started our journey through construction of residential, commercial and industrial projects with the focus on returning maximum profits both in financial and non-financial aspects for our clients.'}
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </StyledInnerBanner>
    );
};

const StyledInnerBanner = styled.section`
  padding-top: calc(560 / 1366 * 100%);
  position: relative;
  overflow: hidden;
  .container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
      display: flex;
      align-items: center;
      height: 100%;
  }

  p{
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-top: 40px;
    color: #f9f9f9;
  }
  h5 {
    color: #F5F5F5;
    z-index: 2;
  }


  @media (max-width: 767px) {
      //padding-top: calc(600 / 375 * 100%);
    //padding-top: 0;
    //
      padding-top: 120px;
      padding-bottom: 120px;
    .container {
      bottom: 40px;
        position: unset;
    }

  }
`;

export default React.memo(InnerBanner);
