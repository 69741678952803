import React, {useEffect} from 'react';
import styled from "styled-components";
import { useMatch, useParams } from 'react-router';
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import OurStory from "../../components/OurStory";
import BusinessSlider from "../../components/business units/BusinessSlider";
import BusinessVideo from "../../components/business units/BusinessVideo";
import ProductSlider from "../../components/business units/ProductSlider";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import {fetchBusinessPostDetail} from "../../api/redux/business";
import BusinessSliderSwiper from "../../components/business units/BusinessSliderSwiper";
import PSMServices from "../../components/business units/PSMServices";

const MyComponent = () => {
    const dispath = useDispatch()

    let {slug} = useParams();
    // api call
    useEffect(() => {
        let api_url = apiEndPoints.CHILD_PAGE;

        let api_params = {
            [ApiParamKey.pageID]: '21',
            [ApiParamKey.sections]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post] : 'yes',

        }

        dispath(fetchBusinessPostDetail([api_url , api_params]))
    }, [])


    //Refactor Data

    const getData = useSelector((state) => state.business);

    const filteredlist = getData?.detail?.data?.list?.filter((e) => e?.page_data?.slug === 'lanco-property-management-solutions')?.[0];

    const banner = filteredlist?.sections.filter((e) => e?.section_data?.template === 'banner')?.[0];
    const story = filteredlist?.sections.filter((e) => e?.section_data?.template === 'overview')?.[0];
    const business = filteredlist?.sections.filter((e) => e?.section_data?.template === 'slider')?.[0];
    const video = filteredlist?.sections.filter((e) => e?.section_data?.template === 'video')?.[0];
    const product = filteredlist?.sections.filter((e) => e?.section_data?.template === 'products')?.[0];





    const match = useMatch("/business/lanco-easy-term");
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{filteredlist?.data?.page_data?.title ? filteredlist?.data?.page_data?.title   : 'Lanco Development Ltd'}</title>
                <meta name="description"  content={filteredlist?.page_data?.meta_desc ? filteredlist?.page_data?.meta_desc :''}/>
                <meta property="og:title" content={filteredlist?.page_data?.og_title ? `${filteredlist?.page_data?.og_title | 'Lanco Development Ltd'}` : ''}/>
                <meta property="og:subtitle" content={filteredlist?.page_data?.og_title ? `${filteredlist?.page_data?.og_title | 'Lanco Development Ltd'}` : ''}/>
                <meta property="og:description" content={filteredlist?.page_data?.og_desc ? filteredlist?.page_data?.og_desc :''}/>
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:image" content={banner?.images?.list?.filter(image => image?.desktop === 'on')?.[0]?.full_path}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledComponent>
                <InnerBanner
                    title={filteredlist?.page_data?.title}
                    img={banner?.images?.list?.filter(image => image?.desktop === 'on')?.[0]?.full_path}
                    imgMob={banner?.images?.list?.filter(image => image?.mobile === 'on')?.[0]?.full_path}
                />
                <OurStory src data={story}/>
                <BusinessSliderSwiper data={business}/>
                <BusinessVideo data={video}/>
                {/*<ProductSlider data={product}/>*/}
                <PSMServices data={product}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
