import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {Container, Row, Col, Form} from 'react-bootstrap';
import { apiEndPoints } from '../../api/network/apiEndPoints';
import { useDispatch, useSelector } from 'react-redux';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import InnerBanner from '../../components/InnerBanner';
import { ApiParam as ApiParamKey } from '../../api/network/apiParams';
import {
    fetchProject,
    fetchProjectFilter,
    fetchProjectALL
} from '../../api/redux/project';
import { useLocation, useNavigate } from 'react-router-dom';
import Select, {components} from "react-select";
import ProjectBox from "../../components/ProjectBox";
import Button from "../../components/Button";
import {text} from "../../styles/globalStyleVars";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const MyComponent = () => {

    const dispath = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();


    // api call
    useEffect(() => {


        let api_url = apiEndPoints.SECTIONS;
        let all_product = apiEndPoints.ALL_PRODUCTS;
        let param = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'projects',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
        };

        let api_url_filter = apiEndPoints.FILTER_PROJECT;

        let param_cat = {
            [ApiParamKey.CATEGORY_ID]: '',
            [ApiParamKey.TYPE]: '',
            [ApiParamKey.LOCATION]: '',
            [ApiParamKey.IMAGE]: 'yes',
            [ApiParamKey.POST]: 'yes',
            [ApiParamKey.GALLERY]: 'yes',
            [ApiParamKey.FILE]: 'yes',
            [ApiParamKey.SPECIFICATION]: 'yes',

        };
        dispath(fetchProject([api_url, param]));
        dispath(fetchProjectFilter([api_url_filter, param_cat]));
        dispath(fetchProjectALL([all_product, param]));
    }, [dispath, location.search]);


    let getPost = useSelector(state => state.project);
    let page = getPost?.project;
    let all_product = getPost?.projectAll;


    const customStyles = {
        dropdownIndicator: (base, state) => ({
            ...base,
            transition: "all .2s ease",
            transform: state.selectProps.menuIsOpen && "rotate(180deg)"
        }),
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? `${text}` : '#1C1C1C',
            backgroundColor: state.isSelected ? `rgba(232, 231, 231, 0.6)` : '#FFF',
            margin: 0,
            fontSize: 14,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 10,
            fontWeight: state.isSelected ? 700 : 400,
            "&:hover": {
                backgroundColor: `${text}`,
                color: '#FFF',
                cursor: 'pointer'
            },

        }), menu: (provided, state) => ({
            ...provided,
            color: '#FFF',
            backgroundColor: state.isSelected ? `${text}` : 'rgba(255,255,255,0)',
            margin: 0,
            borderRadius: 5,
            fontSize: 14,
            // zIndex:10,
            textTransform: 'capitalize',
            zIndex: 99999999,
            // width: 200,
        }), menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#191818' : '#FFF',
            borderRadius: 0,
            fontWeight: '400',
            color: '#FFF',
            fontSize: 14,
        }),

    };

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <CaretDownIcon/>
            </components.DropdownIndicator>
        );
    };

    const CaretDownIcon = () => {
        return <svg xmlns="http://www.w3.org/2000/svg" width="13.414" height="7.414" viewBox="0 0 13.414 7.414">
            <g id="Group_15659" data-name="Group 15659" transform="translate(1479.207 -192.793) rotate(90)">
                <line id="Line_3883" data-name="Line 3883" x2="6" y2="6" transform="translate(193.5 1466.5)" fill="none"
                      stroke="#fff" strokeLinecap="round" stroke-width="1"/>
                <line id="Line_3884" data-name="Line 3884" y1="6" x2="6" transform="translate(193.5 1472.5)" fill="none"
                      stroke="#fff" strokeLinecap="round" stroke-width="1"/>
            </g>
        </svg>
            ;
    };

    //filter values

    const typeas = all_product?.data
        ? Array.from(
            new Set(
                all_product.data
                    .map(item1 => item1?.product_data?.category_slug)
                    .filter(slug => slug) // Filters out falsy values
            )
        ).map(slug => ({
            value: slug,
            label: slug,
        }))
        : [];

    const statuss = all_product?.data
        ? Array.from(new Set(all_product?.data?.map(item1 => item1?.product_data?.type)?.filter(type => type))
        )?.map(type => ({
            value: type,
            label: type,
        }))
        : [];

    const locations = all_product?.data
        ? Array.from(
            new Set(
                all_product?.data?.map(item1 => item1?.product_data?.location)?.filter(location => location)
            )
        )?.map(location => ({
            value: location,
            label: location,
        }))
        : [];
    const searchParams = new URLSearchParams(location.search);
    const project_type = searchParams.get('type');
    const project_location = searchParams.get('location');

    const [selectedType, setSelectedType] = useState(project_type || '');
    const [selectedStatus, setSelectedStatus] = useState();
    const [selectedLocation, setSelectedLocation] = useState(project_location || '');
    const [filteredData, setFilteredData] = useState();





    useEffect(() => {
        // Filter the data based on selected filters
        const newFilteredData = all_product && all_product?.data?.filter(item => {
            const typeMatch = !selectedType || item?.product_data?.category_slug === (project_type ? project_type : selectedType?.value);
            const statusMatch = !selectedStatus || item?.product_data?.type === selectedStatus?.value;
            const locationMatch = !selectedLocation || item?.product_data?.location === (project_location ? project_location : selectedLocation?.value);
            return typeMatch && statusMatch && locationMatch;
        });

        setFilteredData(newFilteredData || []);
        ScrollTrigger.refresh();
    }, [selectedType, selectedStatus, selectedLocation, all_product, location.search]);



    // Callback function to handle selected type
    const handleTypeSelect = (selectedValue) => {
        setSelectedType(selectedValue);
        // setSelectedLocation(selectedValue);
        const newSearchParams = new URLSearchParams(location.search);
        newSearchParams.set('type', selectedValue.value);
        // newSearchParams.set('location', selectedValue.value);
        const newSearch = `?${newSearchParams.toString()}`;
        navigate(`${location.pathname}${newSearch}`);
    };

// Callback function to handle selected status
    const handleStatusSelect = (selectedValue) => {
        setSelectedStatus(selectedValue);
    };

    const handleLocationSelect = (selectedValue) => {
        setSelectedLocation(selectedValue);
        const newSearchParams = new URLSearchParams(location.search);
        newSearchParams.set('location', selectedValue.value);
        const newSearch = `?${newSearchParams.toString()}`;
        navigate(`${location.pathname}${newSearch}`);
    };




    // loadmore functionality
    const [itemsToShow, setItemsToShow] = useState(6);
    const [isCompleted, setIsCompleted] = useState(false)

    const handleLoadMore = () => {
        setItemsToShow((prevItemsToShow) => prevItemsToShow + 6);
        if (itemsToShow + 6 >= filteredData?.length) {
            setIsCompleted(true);
            gsap.delayedCall(0.1, ScrollTrigger.refresh);
        } else {
            setIsCompleted(false);
        }
    };

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{page?.data?.page_data?.title || 'Lanco Development Ltd'}</title>
                <meta name="description" content={page?.data?.page_data?.meta_desc || ''} />
                <meta property="og:title" content={page?.data?.page_data?.og_title || 'Lanco Development Ltd'} />
                <meta property="og:subtitle" content={page?.data?.page_data?.og_title || 'Lanco Development Ltd'} />
                <meta property="og:description" content={page?.data?.page_data?.og_desc || ''} />
                <meta property="og:url" content={window?.location?.href} />
                <meta property="og:image" content={page?.data?.page_images?.list?.find((image) => image?.desktop === 'on')?.full_path || ''} />
                <meta property="og:type" content="website" />
            </Helmet>

            <StyledComponent>
                <InnerBanner
                    title={page?.data?.page_data?.title}
                    img={page?.data?.page_images?.list?.find((image) => image?.desktop === 'on')?.full_path || ''}
                    imgMob={page?.data?.page_images?.list?.find((image) => image?.mobile === 'on')?.full_path || ''}
                />

            </StyledComponent>
            <StyledProjectFilter className="project-filter pt-40 pb-40">
                <Container className="project-filter__form">
                    <Form className={'form'} >
                        <Row>
                            <Col className={'project-filter__form__col'} xs={12} sm={12} md={4}>
                                <div className="contact-map__form">
                                    <div className="form-group">
                                        <Form.Group controlId="formBasicPhone">
                                            <Select
                                                classNamePrefix="filter"
                                                isSearchable={false}
                                                styles={customStyles}
                                                options={statuss} // Use the statusOptions here
                                                onChange={handleStatusSelect}
                                                placeholder={'Select Status'}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                            </Col>

                            <Col className={'project-filter__form__col'} xs={12} sm={12} md={4}>
                                <div className="contact-map__form">

                                    <div className="form-group">
                                        <Form.Group controlId="formBasicPhone">
                                            <Select
                                                classNamePrefix="filter"
                                                isSearchable={false}
                                                options={typeas}
                                                onChange={handleTypeSelect}
                                                placeholder={ selectedType || 'Select Type'}
                                                styles={customStyles}

                                            />
                                        </Form.Group>
                                    </div>

                                </div>
                            </Col>

                            <Col className={'project-filter__form__col'} xs={12} sm={12} md={4}>
                                <div className="contact-map__form">

                                    <div className="form-group">
                                        <Form.Group controlId="formBasicPhone">
                                            <Select
                                                classNamePrefix="filter"
                                                isSearchable={false}
                                                options={locations}
                                                onChange={handleLocationSelect}
                                                placeholder={selectedLocation || 'Select location'}
                                                styles={customStyles}
                                            />
                                        </Form.Group>
                                    </div>

                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </StyledProjectFilter>
            {
                filteredData &&
                <StyledProjectList className="project-list">
                    <Container>
                        <Row>
                            {
                                filteredData && filteredData?.length > 0 ?

                                    filteredData?.slice(0, itemsToShow)?.map((e, i)=>{
                                        // data?.map((e, i)=>{
                                        return(
                                            <Col sm={6} md={6} lg={4} key={i}>
                                                <ProjectBox soldout data={e}/>
                                            </Col>
                                        );
                                    })
                                    :
                                    <p>No Projects Found</p>
                            }
                        </Row>
                        {itemsToShow < filteredData?.length && (
                            <Col md={12}>
                                <div className={'loadmore'} onClick={handleLoadMore}>
                                    <Button text={'Load More'}  background={'transparent'} color={'#1C1C1C'}
                                            hoverBackground={'#535353'}/>
                                </div>
                            </Col>
                        )}
                    </Container>
                </StyledProjectList>
            }

        </HelmetProvider>
    );
};

const StyledComponent = styled.section``;
const StyledProjectFilter = styled.section`
  //background-color: #000000;

  padding-top: 80px;
  padding-bottom: 60px;
    
  
  .project-filter__form {
    .form .filter__control {
      margin-bottom: 0 !important;
    }
  }

  .form-group {
    margin-bottom: 0;

    .filter__placeholder {
      color: #1C1C1C !important;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-transform: capitalize;
    }

    .filter__input {
      input {
        color: rgba(255, 255, 255, 0.6) !important;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }

    .filter__single-value {
      color: #1C1C1C !important;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      opacity: 0.6;
      text-transform: capitalize !important;
    }
  }

  .css-1hb7zxy-IndicatorsContainer{
    margin-right: 10px;
  }
  @media (min-width: 1550px) {
    .project-filter__form {
      .form .filter__control {
        margin-bottom: 30px !important;
      }
    }

    .form-group {
      margin-bottom: 0;

      .filter__placeholder {
        font-size: 14px;
        line-height: 20px;
      }

      .filter__input {
        input {
          font-size: 14px;
          line-height: 20px;
        }
      }

      .filter__single-value {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  @media (max-width: 767px) {
    .project-filter__form__col {
      margin-bottom: 30px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .project-filter__form {
      .form .filter__control {
        margin-bottom: 0 !important;
      }
    }
  }
`;

const StyledProjectList = styled.section`
  position: relative;
  
  padding-bottom: 120px;

  
  
  .loadmore {
    display: flex;
    justify-content: center;
    z-index: 3;
  }

  .featured-projects__mobile {
    display: none;
  }


  .project-box__single {

    position: relative;
    margin-bottom: 60px;

    a {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 2;
      cursor: pointer;
    }

    &:hover {
      .global-image {
        transition: all 0.6s cubic-bezier(0.4, 0, 0, 1);
        transform: scale(1.06);
      }
    }

    &__image {
      position: relative;
      padding-top: calc(450 / 370 * 100%);
      overflow: hidden;

      .global-image {
        filter: grayscale(100%);
        overflow: hidden;
        transition: all 0.6s cubic-bezier(0.4, 0, 0, 1);
      }
      &:after {
        position: absolute;
        content: '';
        background-color: #0d0a0a;
        opacity: 30%;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        transition: all 0.6s cubic-bezier(0.4, 0, 0, 1);
      }
      &:hover {
        .global-image {
          filter: none;
        }
        &:after{
          opacity: 20%;
          transition: all 0.6s cubic-bezier(0.4, 0, 0, 1);
        }
      }
    }

    &__text {
      margin-top: 18px;
      h6 {
        font-size: 24px;
        line-height: 38px;
        color: #1C1C1C;
        font-weight: 400;
      }

      p {
        display: flex;
        font-size: 16px;
        line-height: 24px;
        color: #9B9B9B;
        font-weight: 400;
        margin-bottom: 0;
      }
    }

    &__icon {
      position: absolute;
      top: 30px;
      left: 0;
      //transform: translate(-50% , -50%);
      cursor: pointer;
      z-index: 999999;


      p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: #fff;
        background-color: #FF0000;
        padding: 12px 42px 16px 35px;
      }
    }
    
  }

  .dot {
    display: flex;
    justify-content: end;
    align-items: end;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #9B9B9B;
    margin-right: 10px;
    margin-left: 10px;
  }
  

  @media only screen and (min-width: 992px) and (max-width: 1050px) {
    .featured-projects__main {
      .project-slider {

        &__single {

          &:hover {

            .project-slider__single__hover {
              .content {
                height: 180px;
                width: 180px;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (min-width: 1050px) and (max-width: 1150px) {
    .featured-projects__main {
      .project-slider {

        &__single {

          &:hover {

            .project-slider__single__hover {
              .content {
                height: 210px;
                width: 210px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {

    .bg-image-top {
      display: none;
    }

    .bg-image-bottom {
      display: none;
    }

    .featured-projects__web {
      display: none;
    }

    .bottom {
      display: flex;
    }

    .load-more {
      display: none;
    }

    .featured-projects__mobile {
      display: block;
      padding-right: 0px;
      padding-left: 18px;
    }

    .project-slider {

      .swiper {
        padding-right: 100px;
      }
    }

    .project-slider__single__inner__content {
      position: relative;

      h5 {
        position: absolute;
        font-size: 20px;
        font-weight: 600;
        line-height: 20px;
        bottom: 60px;
      }
    }

    .project-slider__single__hover {

      .content {
        display: none;
      }

      p {
        display: none;
      }
    }


  }

  @media (min-width: 1550px) {

    .project-slider__single__inner__content {
      h5 {
        font-size: 24px !important;
        line-height: 28px !important;
      }
    }

    .content__inner {
      h6 {
        min-width: 130px;
        font-size: 16px !important;
        line-height: 22px !important;
      }
    }

    .project-slider__single__hover p {
      font-size: 18px !important;
      line-height: 24px !important;
    }

    .featured-projects__main {
      &__top {
        p {
          font-size: 18px;
          line-height: 24px !important;
        }
      }
    }
  }

  @media (max-width: 374px) {
    .project-slider__single__inner__content {
      h5 {
        padding: 0 20px !important;
        bottom: 20px !important;
      }
    }
  }
`

export default MyComponent;
