import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {get} from "../../network/axiosServices";


const initialState = {
    loading: false,
    posts: [],
    error: '',
    projectLoading:false,
    projectPosts:[],
    projectError:'',
    detail: [],
    detailLoading: false,
    detailError: ''
}

export const fetchProject = createAsyncThunk("fetchProject", (params) => {
    return get(params);
});


export const fetchProjectFilter = createAsyncThunk("fetchProjectFilter", (params) => {
    return get(params);
});

export const fetchProjectALL = createAsyncThunk("fetchProjectALL", (params) => {
    return get(params);
});

export const fetchProjectPostDetail = createAsyncThunk("fetchProjectPostDetail", (params) => {
    return get(params);
});


const postSlice = createSlice({
    name: 'project',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchProject.pending, (state) => {
            state.loading = true
            state.project = []
            state.error = ''
        })
        builder.addCase(fetchProject.fulfilled, (state, action) => {
            state.loading = false
            state.project = action.payload
            state.error = ''
        })
        builder.addCase(fetchProject.rejected, (state, action) => {
            state.loading = false
            state.project = []
            state.error = action.error
        })
        builder.addCase(fetchProjectFilter.pending, (state) => {
            state.loading = true
            state.projectFilter = []
            state.error = ''
        })
        builder.addCase(fetchProjectFilter.fulfilled, (state, action) => {
            state.loading = false
            state.projectFilter = action.payload
            state.error = ''
        })
        builder.addCase(fetchProjectFilter.rejected, (state, action) => {
            state.loading = false
            state.projectFilter = []
            state.error = action.error
        })
        builder.addCase(fetchProjectALL.pending, (state) => {
            state.loading = true
            state.projectAll = []
            state.error = ''
        })
        builder.addCase(fetchProjectALL.fulfilled, (state, action) => {
            state.loading = false
            state.projectAll = action.payload
            state.error = ''
        })
        builder.addCase(fetchProjectALL.rejected, (state, action) => {
            state.loading = false
            state.projectAll = []
            state.error = action.error
        })
        builder.addCase(fetchProjectPostDetail.pending, (state, action) => {
            state.detailLoading = true
            state.detail = []
            state.detailError = ''
        })
        builder.addCase(fetchProjectPostDetail.fulfilled, (state, action) => {
            state.detailLoading = false
            state.detail = action.payload
            state.detailError = ''
        })
        builder.addCase(fetchProjectPostDetail.rejected, (state, action) => {
            state.detailLoading = false
            state.detail = []
            state.detailError = action.error
        })
    }
})


export default postSlice.reducer
