import React, { useEffect} from 'react';
import styled from 'styled-components';
import {Col, Container, Form, Row} from "react-bootstrap";
import Button from "./Button";
import {Img} from "./Img";


const ListWithForm = ({link, img}) => {

console.log('link', link)
    return (
        <StyledListWithForm className={'list_with_form'}>
            <a href={link} target={"_blank"} rel="noopener noreferrer">
                <Img src={img}/>
            </a>

        </StyledListWithForm>
    )
};

const StyledListWithForm = styled.div`
  position: relative;
  padding-top: calc(500 / 1366 * 100%);
  @media(max-width: 767px){
    padding-top: calc(470 / 570 * 100%);
  }
`;


export default React.memo(ListWithForm);
