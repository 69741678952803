import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Title from "../Title";
import Button from "../Button";
import {BsChevronLeft, BsChevronRight} from 'react-icons/bs';
// import {black} from "../../styles/globalStyleVars";
import {useEffect, useRef, useState} from "react";
import VisibilitySensor from "react-visibility-sensor";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, Pagination} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {hover, title} from '../../styles/globalStyleVars'
import {Link} from "react-router-dom";
import {Img} from "../Img";
import NewsBox from "../NewsBox";

const BlogSliderV1 = ({data}) => {
    // SwiperCore.use([Autoplay]);
    let leftRef = useRef();
    let rightRef = useRef();
    let mLeftRef = useRef();
    let mRightRef = useRef();
    let containerRef = useRef();
    let sliderRef = useRef();
    const [current, setCurrent] = useState('')
    let [offset, setOffset] = useState(90)
    let [theWidth, SetTheWidth] = useState(0)
    let [activeNumber, setActiveNumber] = useState(1)
    let [totalNumber, setTotalNumber] = useState(5)




    useEffect(() => {
        setOffset(containerRef.current?.offsetLeft)
        window.addEventListener('resize', () => {
            setOffset(containerRef.current?.offsetLeft)
            SetTheWidth(window.innerWidth)
        })

        // slider number
        const getActiveItem = document.querySelector('.swiper-pagination-current')?.innerHTML
        const getTotalItem = document.querySelector('.swiper-pagination-total')?.innerHTML
        setActiveNumber(getActiveItem)
        setTotalNumber(getTotalItem)
    }, [null])





    useEffect(() => {
        const getTotalItem = 5
        setTotalNumber(getTotalItem)
    }, [data])
    let sliderNumber = () => {
        const getActiveItem = document.querySelector('.swiper-pagination-current').innerHTML
        // const getTotalItem = document.querySelector('.swiper-pagination-total').innerHTML
        setActiveNumber(getActiveItem)
        // setTotalNumber(getTotalItem)
    }



    return (
        <StyledBlog offset={offset} className='blog-slider pt-150'>
            <Container ref={containerRef}>
                <Row>
                    <Col sm={12}>
                        <div className="blog-title">
                            <p>Blog</p>
                            <p>{activeNumber}/{data?.length}</p>
                        </div>


                        <div className="blog-button">
                            <Button src={'/news-events'} text={'View All'} background={'transparent'} color={'#1C1C1C'}
                                    hoverBackground={'#535353'}/>
                            <div className="slider-nav">
                                <ul>
                                    <li className="hover slider_prev">
                                        <svg stroke="currentColor" fill="#1C1C1C" stroke-width="0"
                                             viewBox="0 0 16 16" height="1em" width="1em"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd"
                                                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"></path>
                                        </svg>
                                    </li>
                                    <li className="hover slider_next">
                                        <svg stroke="currentColor" fill="#1C1C1C" stroke-width="0"
                                             viewBox="0 0 16 16" height="1em" width="1em"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd"
                                                  d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"></path>
                                        </svg>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            <VisibilitySensor offset={{top: -150}}>
                {({isVisible}) =>
                    <div className={` fade-up blog-slider__slider-wrap`}>
                        <div className="blog-slider__slider-wrap__inner">
                            {
                                data &&
                                <Swiper loop={true}
                                        spaceBetween={30}
                                        slidesPerView={3}
                                        allowSlideNext={true}
                                        allowSlidePrev={true}
                                        allowTouchMove={true}
                                        speed={900}
                                        pagination={{
                                            type: "fraction",
                                        }}
                                        navigation={{
                                            prevEl: '.slider_prev',
                                            nextEl: '.slider_next',
                                        }}
                                        modules={[Autoplay, Pagination, Navigation]}

                                        autoplay={{
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        }}
                                        breakpoints={{
                                            320: {
                                                slidesPerView: 1,
                                                spaceBetween: 30,
                                            },
                                            991: {
                                                slidesPerView: 2,
                                                spaceBetween: 30,
                                            },
                                            1024: {
                                                slidesPerView: 3,
                                                spaceBetween: 30,
                                            },
                                        }}
                                        onSlideChange={(s) => sliderNumber()}

                                    // navigation={true} modules={[Navigation]}
                                        onSwiper={(swiper) => console.log(swiper)}

                                        ref={sliderRef}>
                                    {
                                        data?.length > 0 && data?.map((e,i)=>{
                                            const dateObject = new Date(e?.data?.date);
                                            const getDate = dateObject?.getDate();
                                            const getMonth = dateObject.toLocaleString('default', { month: 'long' });
                                            const getYear = dateObject.getFullYear();

                                            return(
                                                <SwiperSlide key={i}>

                                                    <NewsBox data={e} date={getDate} month={getMonth} year={getYear}/>
                                                </SwiperSlide>
                                            );
                                        })
                                    }


                                </Swiper>
                            }

                        </div>

                        <div className="slider-nav-mobile">
                            <ul>
                                <li className="hover slider_prev">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                         viewBox="0 0 16 16" height="1em" width="1em"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd"
                                              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"></path>
                                    </svg>
                                </li>
                                <li className="hover slider_next">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                         viewBox="0 0 16 16" height="1em" width="1em"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd"
                                              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"></path>
                                    </svg>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
            </VisibilitySensor>
        </StyledBlog>
    );
};

const StyledBlog = styled.section`
  .blog-title {
    //position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    padding-bottom: 18px;
    border-bottom: 1px solid rgb(108 108 108 / 10%);
    p {
      //position: absolute;
      //top: 0;
      //right: 0;
      color: #1C1C1C;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    }

  }

  .blog-single {
    .blog-single__inner {
      padding-top: calc(460 / 370 * 100%);
      position: relative;

      a {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 3;
      }

      &__content {
        &__top {
          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #F5F5F5;
            position: absolute;
            left: 40px;
            top: 40px;
            z-index: 2;
            right: 40px;
            margin: 0;
            transform: translateY(-30px);
            opacity: 0;
            transition: all .6s ease;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
          }

          h2 {
            position: absolute;
            top: 40px;
            left: 40px;
            right: 40px;
            z-index: 2;
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
            color: #F5F5F5;
            margin: 0;
            transition: all .3s ease;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
          }
        }

        &__bottom {
          position: absolute;
          margin: 0;
          left: 40px;
          right: 40px;
          bottom: 24px;
          display: flex;
          justify-content: space-between;
          border-top: 1px solid #F5F5F5;
          padding-top: 20px;
          z-index: 2;

          h4 {
            font-family: ${title};
            font-size: 60px;
            //font-weight: 600;
            color: #F5F5F5;
            line-height: 60px;
            transition: color .3s ease;
          }

          p {
            font-size: 16px;
            color: #F5F5F5;
            text-align: right;
            font-weight: 400;
            line-height: 24px;
            transition: color .3s ease;

            span {
              display: block;
              color: #F5F5F5;
            }
          }
        }
      }

      &:hover {
        .blog-single__inner__content__top {
          h2 {
            opacity: 0;
            transform: translateY(-20px);
          }

          p {
            transform: none;
            opacity: 1;
          }
        }

        .blog-single__inner__content__bottom {
          border-color: #FFF;

          h4 {
            color: #ffffff;
          }

          p {
            color: #ffffff;

            span {
              color: #ffffff;
            }
          }
        }
      }

    }


    @media (max-width: 767px) {
      .blog-single__inner__content__top {
        p, h2 {
          left: 30px;
          right: 30px;
          top: 30px;
        }
      }

      .blog-single__inner__content__bottom h4, .blog-single__inner__content__bottom p {
        left: 30px;
        right: 30px;
        top: 30px;
      }

    }
  }

  .blog-button {
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    align-items: center;

    .slider-nav {
      ul {
        display: flex;
      }

      li {
        height: 40px;
        width: 40px;
        background-color: transparent;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        border: 1px solid #1C1C1C;

        &:nth-of-type(1) {
          margin-right: 20px;
        }

        svg {
          color: #1C1C1C;
          z-index: 2;
        }
        &:hover{
          svg {
            fill: #ffffff !important;
           
          }
        }
      }
    }
  }

  .swiper-button-next, .swiper-button-prev {
    position: absolute;
    height: 40px;
    width: 40px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 99;
    background-color: red;
  }

  .swiper-button-next, .swiper-button-prev, .swiper-pagination {
    opacity: 0;
    visibility: hidden;
  }

  .swiper-initialized {
    //padding-right: 300px;
    //padding-left: 300px;
      // margin-right: ${props => props.offset + 15}px;
    margin-left: ${props => props.offset + 15}px;
    padding-right: ${props => props.offset + 15}px;
  }

  .slider-nav-mobile {
    display: none;
  }

  .blog-slider {
    &__slider-wrap {
      &__inner {
        .blog-single {
          &__inner {
            padding-top: 115%;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .swiper-container {
      margin-left: 0;
      padding-right: 0;
    }

    .blog-slider {
      &__slider-wrap {
        margin-left: 15px;
        margin-right: 15px;

        .slider-nav-mobile {
          margin-top: 40px;

          ul {
            display: flex;
          }

          li {
            height: 40px;
            width: 40px;
            background-color: transparent;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            border-radius: 50%;
            cursor: pointer;
            border: 1px solid #1C1C1C;

            &:nth-of-type(1) {
              margin-right: 20px;
            }

            svg {
              fill: #1c1c1c;
              z-index: 2;
            }
            &:hover{
              svg {
                fill: #ffffff !important;

              }
            }
          }
        }
      }
    }

    .blog-button {
      margin-bottom: 40px;

      .slider-nav {
        display: none;
      }
    }

    .slider-nav-mobile {
      display: block;
    }

  }

`;
export default BlogSliderV1;