import React from 'react';
import styled from "styled-components";
import {Img} from "./Img";
import {Link} from "react-router-dom";
import moment from "moment";
import reactHtmlParser from "react-html-parser";
import {title} from "../styles/globalStyleVars";


const MyComponent = ({data, date, month, year}) => {

    const imageArr = Array.isArray(data?.images?.list)
        ? data.images.list.filter((item) => item?.thumb === 'on')
        : [];




    return (
        <StyledComponent>
            <div className="blog-single">
                <div className="blog-single__inner">
                    <Link to={`/news/${data?.data?.slug}`}></Link>
                    <Img
                        src={imageArr?.[0]?.full_path}
                        objectFit={'cover'} layout={'fill'}/>
                    <div className="blog-single__inner__content">
                        <div className="blog-single__inner__content__top">
                            <p>{reactHtmlParser(data?.data?.subtitle)}</p>
                            <h2>{reactHtmlParser(data?.data?.title)}</h2>

                        </div>

                        <div className="blog-single__inner__content__bottom">
                            <h4>{date}</h4>
                            <p>{month}
                                <span>{year}</span></p>
                        </div>

                    </div>
                </div>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .blog-single {
    .blog-single__inner {
      padding-top: calc(460 / 370 * 100%);
      position: relative;
        &:after{
            content: '';
            height: 100%;
            width: 100%;
            background-color: rgb(0 0 0 / 50%);
            top: 0;
            left: 0;
            right: 0;
            position: absolute;
        }

      a {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 3;
      }

      &__content {
        &__top {
          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #F5F5F5;
            position: absolute;
            left: 40px;
            top: 40px;
            z-index: 2;
            right: 40px;
            margin: 0;
            transform: translateY(-30px);
            opacity: 0;
            transition: all .6s ease;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
          }

          h2 {
            position: absolute;
            top: 40px;
            left: 40px;
            right: 40px;
            z-index: 2;
            font-size: 28px;
            font-weight: 400;
            line-height: 34px;
            color: #F5F5F5;
            margin: 0;
            transition: all .3s ease;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
          }
        }

        &__bottom {
          position: absolute;
          margin: 0;
          left: 40px;
          right: 40px;
          bottom: 24px;
          display: flex;
          justify-content: space-between;
          border-top: 1px solid #F5F5F5;
          padding-top: 20px;
          z-index: 2;

          h4 {
            font-family: ${title};
            font-size: 60px;
            //font-weight: 600;
            color: #F5F5F5;
            line-height: 60px;
            transition: color .3s ease;
          }

          p {
            font-size: 16px;
            color: #F5F5F5;
            text-align: right;
            font-weight: 400;
            line-height: 24px;
            transition: color .3s ease;

            span {
              display: block;
              color: #F5F5F5;
            }
          }
        }
      }

      &:hover {
        .blog-single__inner__content__top {
          h2 {
            opacity: 0;
            transform: translateY(-20px);
          }

          p {
            transform: none;
            opacity: 1;
          }
        }

        .blog-single__inner__content__bottom {
          border-color: #FFF;

          h4 {
            color: #ffffff;
          }

          p {
            color: #ffffff;

            span {
              color: #ffffff;
            }
          }
        }
      }

    }
      


    @media (max-width: 767px) {
      .blog-single__inner__content__top {
        p, h2 {
          left: 30px;
          right: 30px;
          top: 30px;
        }
      }

      .blog-single__inner__content__bottom h4, .blog-single__inner__content__bottom p {
        left: 30px;
        right: 30px;
        top: 30px;
      }

    }
  }
`;

export default React.memo(MyComponent);
