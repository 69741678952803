import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Modal from "react-bootstrap/Modal";
import {Col, Container, Form, Row} from "react-bootstrap";

import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import {Img} from "./Img";
import Button from "./Button";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {apiEndPoints} from "../api/network/apiEndPoints";
import {postForm} from "../api/redux/home";
import {ApiParam as ApiParamKey} from "../api/network/apiParams";
import {fetchSubscriptionData} from "../api/redux/subscription";
import reactHtmlParser from "react-html-parser";



const PopupV3 = ({
                     show,
                     handleClose,
                     no_img,
                     onClose,
                     item,
                     title,
                     description,
                     data,
                     subtitle,
                     designation,
                     img
                 }) => {



    const popupData = data;
    const [winWidth, setWinWidth] = useState(true)
    useEffect(() => {
        if (window.innerWidth > 767) {
            setWinWidth(true)
        } else {
            setWinWidth(false)
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth > 767) {
                setWinWidth(true)
            } else {
                setWinWidth(false)
            }
        });
    }, [])



    const dispatch = useDispatch()
    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.PAGE_DATA;

        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'subscription-form',
            [ApiParamKey.image]: 'yes',

        }

        dispath(fetchSubscriptionData([api_url , api_params]))
    }, [dispath])


    let getPost = useSelector(store => store.subscription)


    const {register, handleSubmit ,formState,reset,control} = useForm({mode: 'all'});


    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });



    const onSubmit = async (e) => {
        try {
            let api_services = apiEndPoints.BUYER_FORM;

            var formData = new FormData();
            formData.append('name', e?.name);
            formData.append('email', e?.email);
            formData.append('form_id', 'subscription-form');

            const response = await dispatch(postForm([api_services, formData]));

            if (response && response.payload?.status === 1) {
                success(response.payload?.message);
                reset(); // Reset form fields
            } else if (response) {
                error(response?.payload?.response?.data?.message);
                reset(); // Reset form fields
            } else {
                error('Failed to submit form. Please try again later.');
            }
        }catch (error) {
            error('Failed to submit form. Please try again later.');
        }
    };
    let count = 0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if (count > 0) {
            toast.error('Please fill out the correct inputs');
        }
        count = 0;
    };


    return (

        <StyledModal>
            <Modal
                onHide={onClose}
                backdrop="static"
                keyboard={false}
                show={show}
                className="notice_modal"
            >
                <div onClick={onClose} className="modal-close hover">
                    <svg xmlns="http://www.w3.org/2000/svg" width="11.414" height="11.414" viewBox="0 0 11.414 11.414">
                        <g id="Group_15651" data-name="Group 15651" transform="translate(-13907.793 -19.793)">
                            <line id="Line_3833" data-name="Line 3833" x2="10" y2="10"
                                  transform="translate(13908.5 20.5)" fill="none" stroke="#4f616b"
                                  stroke-linecap="round" stroke-width="1"/>
                            <line id="Line_3834" data-name="Line 3834" x1="10" y2="10"
                                  transform="translate(13908.5 20.5)" fill="none" stroke="#4f616b"
                                  stroke-linecap="round" stroke-width="1"/>
                        </g>
                    </svg>
                </div>
                <Container>
                    <Modal.Body>
                        <Row>
                            <Col md={4} className={'content-left'}>
                                <div className={'img_wrapper'}>
                                    <Img src={getPost?.posts?.data?.images?.list?.[0]?.full_path}/>
                                </div>
                            </Col>
                            <Col md={8} className={'content-right'}>
                                <div className={'wrapper'}>
                                    <h2>{reactHtmlParser(getPost?.posts?.data?.page_data?.short_desc)}</h2>
                                    <Form className={'form'} onSubmit={handleSubmit(onSubmit, onError)}>
                                        <div className="form-group">
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Control
                                                    className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                    {...register("name",{
                                                        required: 'Name is required',
                                                        pattern: {
                                                            value: /^[A-Za-z]+$/,
                                                            message: 'Name must contain only letters',
                                                        },
                                                    })}
                                                    type="text" placeholder="Name*"/>
                                            </Form.Group>
                                        </div>
                                        <div className="form-group">
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Control
                                                    className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                    {...register("email",{
                                                        required:{
                                                            value:true,
                                                            message:'please enter your email'
                                                        },
                                                        pattern:{
                                                            value:/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                            message:'please enter a valid email address'
                                                        }
                                                    })}
                                                    type="email" placeholder="Email*"/>
                                            </Form.Group>
                                        </div>

                                        <div className={'btn'} onClick={handleSubmit(onSubmit,onError)}>
                                            <Button text={'Submit Message'}
                                                    background={'transparent'} color={'#1C1C1C'}
                                                    hoverBackground={'#535353'}/>
                                        </div>

                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Container>
            </Modal>
        </StyledModal>

    )
};


const StyledModal = styled.div`

  .modal-dialog {
    margin: 0;
  }

  h3 {
    color: #180E81;
  }


`;


export default PopupV3;
