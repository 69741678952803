import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import PsmCard from "./PsmCard";
import {title} from "../../styles/globalStyleVars";

const MyComponent = ({data}) => {
    return (
        <StyledComponent className={'pt-120 pb-120'}>
            <Container>
                <h5 className={'split-up'}>{data?.section_data?.subtitle}</h5>
                <Row>
                    {
                        data?.posts?.list && data?.posts?.list?.length > 0 &&
                        data?.posts?.list?.map((e,i)=>{
                            return(
                                <Col md={3} key={i}>
                                    <PsmCard
                                        img={e?.images?.[0]?.full_path}
                                        title={e?.data?.title}
                                        desc={e?.data?.short_desc}
                                    />
                                </Col>
                            );
                        })
                    }
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    background-color: #f2f2f2;
    h5{
        margin-bottom: 40px;
        font-family: ${title};
    }
    .col-md-3{
        margin-bottom: 30px;
        &:nth-last-child(-n+4) {
            margin-bottom: 0; /* Set to 0 for the last four columns */
        }
    }
    
    @media(max-width: 767px){
        .col-md-3{
            margin-bottom: 30px;
            &:nth-last-child(-n+4) {
                margin-bottom: 30px; /* Set to 0 for the last four columns */
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
`;

export default MyComponent;
