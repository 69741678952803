import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {

    const [offset, setOffset] = useState(90)

    useEffect(() => {
        setOffset(document.querySelector(' .container').offsetLeft)
    }, [])


    const rows = [
        {
            title: 'Md. Khandakar Alfaz ahmed',
            desig: 'Managing Director',
            content:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            imageSrc: '/images/dynamic/management/md.jpg',
        },
        {
            title: 'Khandakar Subrina Jahan',
            desig: 'Director',
            content:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            imageSrc: '/images/dynamic/management/director.jpg',
        },
    ];


    return (
        <StyledComponent className={''} offset={offset}>
            <Container fluid>
                <Row  className={`values pt-150 pb-150`} style={{paddingLeft: offset + 15 + 'px', paddingRight: offset + 15 + 'px'}} >
                    <Col md={5}
                         className={'values__image'}
                    >
                        <div className={'img-wrapper'}>
                            <Img src={data?.images?.list?.[0]?.full_path} />
                        </div>
                    </Col>
                    <Col  md={{span:6, offset:1}}
                          className={'values__content'} >
                        <div className={'content-wrapper'}>
                            <h6>{data?.section_data?.subtitle}</h6>
                            <span>{data?.section_data?.short_desc}</span>
                            <p className={'split-up'}>{reactHtmlParser(data?.section_data?.description)}</p>
                        </div>
                    </Col>

                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .values{

    &:nth-child(even){
      flex-direction: row-reverse;
    }
    &__content{
      .content-wrapper{
        h6{
          font-size: 24px;
          line-height: 36px;
          font-weight: 500;
          margin-bottom: 8px;
          color: #1C1C1C;
        }
        span{
          font-size: 16px;
          line-height: 24px;
          color: #9B9B9B;
         
        }
        p{
          margin-top: 40px;
          color: #1C1C1C;
        }
        ul{
          li{
            color: #000000;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            padding-bottom: 15px;
            margin-bottom: 28px;
            border-bottom: 1px solid #EAEAEA;
            &:last-child{
              margin-bottom: 0;
            }
          }
        }
      }
      @media(max-width: 767px){
        margin-top: 40px;
      }
    }
    &__image{
      .img-wrapper{
        position: relative;
        padding-top: calc(450 / 500 * 100%);
      }
    }
    @media(max-width: 767px){
      padding-left: 15px;
      padding-bottom: 15px;
    }
  }

  .even-row{
    background-color: #ffffff;

  }
`;

export default MyComponent;
