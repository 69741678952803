import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
// import { body_ms14, body_ms16, gradientColor, hover, text, title } from "../../styles/globalStyleVars";
import moment from "moment";
import "react-modal-video/css/modal-video.min.css";
import {useDispatch, useSelector} from "react-redux";
import {fetchSocial} from "../api/redux/social";
import {apiEndPoints} from "../api/network/apiEndPoints";

const MyComponent = () => {
    let [open, setOpen] = useState(false);
    let [videoId, setVideo] = useState("");
    let [windowWidth, setWindowWidth] = useState("");

    let handelOpen = (open, id) => {
        setOpen(open);
        setVideo(id);
    };

    const dispath = useDispatch()
    useEffect(() => {
        let api_url = apiEndPoints.SOCIAL
        dispath(fetchSocial([api_url]))
    }, [dispath])


    let getPost = useSelector(store => store.social)




    return (
        <>
            <StyledComponent className="footer pb-60 pt-120">
                <Container>
                    <Row>
                        <Col sm={4} className="footer__logo">
                            <a href={`tel:${getPost?.posts?.data?.office_phone}`}><h5>{getPost?.posts?.data?.office_phone}</h5></a>
                            <a href={`mailto:${getPost?.posts?.data?.info_email}`}><p>{getPost?.posts?.data?.info_email}</p></a>
                            <ul>
                                {
                                    getPost?.posts?.data?.facebook &&
                                    <li className="hover-here">
                                        <Link target="_blank" to={getPost?.posts?.data?.facebook}>
                                            <svg id="Group_21709" data-name="Group 21709"
                                                 xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                 viewBox="0 0 18 18">
                                                <rect id="Rectangle_5835" data-name="Rectangle 5835" width="18"
                                                      height="18" fill="#9b9b9b" opacity="0"/>
                                                <g id="facebook-app-symbol" transform="translate(5)">
                                                    <path id="f_1_"
                                                          d="M11.836,18V9.79h2.755L15,6.589H11.836V4.546c0-.926.256-1.558,1.586-1.558h1.693V.125A22.961,22.961,0,0,0,12.648,0,3.856,3.856,0,0,0,8.532,4.229v2.36H5.769v3.2H8.532V18Z"
                                                          transform="translate(-5.769)" fill="#9b9b9b"/>
                                                </g>
                                            </svg>

                                        </Link>
                                    </li>
                                }

                                {
                                    getPost?.posts?.data?.linkedin &&
                                    <li className="hover-here">
                                        <Link target="_blank" to={getPost?.posts?.data?.linkedin}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18"
                                                 viewBox="0 0 19 18">
                                                <g id="Group_21710" data-name="Group 21710" transform="translate(0 0)">
                                                    <rect id="Rectangle_5836" data-name="Rectangle 5836" width="18"
                                                          height="18" fill="#9b9b9b" opacity="0"/>
                                                    <g id="_x31_0.Linkedin" transform="translate(1 0)">
                                                        <path id="Path_6021" data-name="Path 6021"
                                                              d="M23.122,22.9V16.305c0-3.24-.7-5.715-4.478-5.715a3.907,3.907,0,0,0-3.533,1.935h-.045V10.882H11.49V22.9h3.735V16.935c0-1.575.292-3.083,2.227-3.083,1.912,0,1.935,1.778,1.935,3.173v5.85h3.735Z"
                                                              transform="translate(-5.123 -4.897)" fill="#9b9b9b"/>
                                                        <path id="Path_6022" data-name="Path 6022"
                                                              d="M3.39,10.98H7.125V23H3.39Z"
                                                              transform="translate(-3.097 -4.995)" fill="#9b9b9b"/>
                                                        <path id="Path_6023" data-name="Path 6023"
                                                              d="M5.16,3A2.171,2.171,0,1,0,7.32,5.16,2.161,2.161,0,0,0,5.16,3Z"
                                                              transform="translate(-3 -3)" fill="#9b9b9b"/>
                                                    </g>
                                                </g>
                                            </svg>

                                        </Link>
                                    </li>
                                }

                                {
                                    getPost?.posts?.data?.youtube &&
                                    <li className="hover-here">
                                        <Link target="_blank" to={getPost?.posts?.data?.youtube}>
                                            <svg id="Group_21711" data-name="Group 21711"
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 width="20" height="20" viewBox="0 0 20 20">
                                                <rect id="Rectangle_5837" data-name="Rectangle 5837" width="20"
                                                      height="20"
                                                      fill="#9b9b9b" opacity="0"/>
                                                <g id="youtube" transform="translate(0 3)">
                                                    <path id="youtube-2" data-name="youtube"
                                                          d="M19.588,1.963A2.506,2.506,0,0,0,17.825.2C16.26-.228,10-.228,10-.228s-6.26,0-7.825.412A2.556,2.556,0,0,0,.412,1.963,26.4,26.4,0,0,0,0,6.774a26.3,26.3,0,0,0,.412,4.81,2.506,2.506,0,0,0,1.763,1.763c1.581.428,7.825.428,7.825.428s6.26,0,7.825-.412A2.506,2.506,0,0,0,19.588,11.6,26.41,26.41,0,0,0,20,6.79a25.061,25.061,0,0,0-.412-4.827ZM8.007,9.772v-6l5.206,3Zm0,0"
                                                          transform="translate(0 0.228)" fill="#9b9b9b"/>
                                                </g>
                                            </svg>

                                        </Link>
                                    </li>
                                }

                                {
                                    getPost?.posts?.data?.twitter &&
                                    <li className="hover-here">
                                        <Link target="_blank" to={getPost?.posts?.data?.twitter}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="11.035" height="10.329"
                                                 viewBox="0 0 11.035 11.329">
                                                <path id="path1009"
                                                      d="M281.527,167.31l4.26,5.7-4.287,4.632h.965l3.754-4.055,3.033,4.055h3.284l-4.5-6.017,3.991-4.311h-.965l-3.457,3.735-2.793-3.735Zm1.419.711h1.509l6.661,8.907h-1.509Z"
                                                      transform="translate(-281.5 -167.31)" fill="#c9c9c9"/>
                                            </svg>
                                        </Link>
                                    </li>
                                }

                                {
                                    getPost?.posts?.data?.instagram &&
                                    <li>
                                        <Link target="_blank" to={getPost?.posts?.data?.instagram}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 viewBox="0 0 16 16">
                                            <path id="Path_5912" data-name="Path 5912"
                                                      d="M8,1.442c2.136,0,2.389.008,3.233.046a4.425,4.425,0,0,1,1.485.276,2.479,2.479,0,0,1,.92.6,2.479,2.479,0,0,1,.6.92,4.43,4.43,0,0,1,.276,1.485c.038.844.046,1.1.046,3.233s-.008,2.389-.046,3.233a4.43,4.43,0,0,1-.276,1.485,2.649,2.649,0,0,1-1.518,1.518,4.425,4.425,0,0,1-1.485.276c-.843.038-1.1.046-3.233.046s-2.389-.008-3.233-.046a4.425,4.425,0,0,1-1.485-.276,2.479,2.479,0,0,1-.92-.6,2.479,2.479,0,0,1-.6-.92,4.425,4.425,0,0,1-.276-1.485c-.038-.844-.046-1.1-.046-3.233s.008-2.389.046-3.233a4.425,4.425,0,0,1,.276-1.485,2.479,2.479,0,0,1,.6-.92,2.479,2.479,0,0,1,.92-.6,4.425,4.425,0,0,1,1.485-.276c.844-.038,1.1-.046,3.233-.046M8,0C5.827,0,5.555.009,4.7.048A5.873,5.873,0,0,0,2.76.42a3.921,3.921,0,0,0-1.417.923A3.921,3.921,0,0,0,.42,2.76,5.873,5.873,0,0,0,.048,4.7C.009,5.555,0,5.827,0,8s.009,2.445.048,3.3A5.873,5.873,0,0,0,.42,13.24a3.921,3.921,0,0,0,.923,1.417,3.923,3.923,0,0,0,1.417.923,5.873,5.873,0,0,0,1.942.372C5.555,15.991,5.827,16,8,16s2.445-.009,3.3-.048a5.873,5.873,0,0,0,1.942-.372,4.09,4.09,0,0,0,2.34-2.34,5.873,5.873,0,0,0,.372-1.942c.039-.853.048-1.126.048-3.3s-.009-2.445-.048-3.3A5.873,5.873,0,0,0,15.58,2.76a3.923,3.923,0,0,0-.923-1.417A3.921,3.921,0,0,0,13.24.42,5.873,5.873,0,0,0,11.3.048C10.445.009,10.173,0,8,0"
                                                      transform="translate(0 0)" fill="#9b9b9b"/>
                                                <path id="Path_5913" data-name="Path 5913"
                                                      d="M24.784,20.676a4.108,4.108,0,1,0,4.108,4.108,4.108,4.108,0,0,0-4.108-4.108m0,6.775a2.667,2.667,0,1,1,2.667-2.667,2.667,2.667,0,0,1-2.667,2.667"
                                                      transform="translate(-16.784 -16.784)" fill="#9b9b9b"/>
                                                <path id="Path_5914" data-name="Path 5914"
                                                      d="M62.007,15.673a.96.96,0,1,1-.96-.96.96.96,0,0,1,.96.96"
                                                      transform="translate(-48.777 -11.943)" fill="#9b9b9b"/>
                                            </svg>
                                        </Link>
                                    </li>
                                }
                            </ul>
                        </Col>
                        <Col className="p-0 listing" md={2}>
                            <ul>
                                <li>
                                    <Link to={"/"}>
                                        Home
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/our-story"}>
                                        Our Story
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/board-of-director"}>
                                        Board of Director
                                    </Link>
                                </li>
                            </ul>
                        </Col>
                        <Col className="listing p-0" md={2}>
                            <ul>
                                <li>
                                    <Link to={"/mission-vision"}>
                                        Mission Vision
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/management-board"}>
                                        Management Team
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/project"}>
                                        Projects
                                    </Link>
                                </li>
                                {/*<li><Link to={'/'}>Annual Reports</Link></li>*/}
                            </ul>
                        </Col>
                        <Col className="listing p-0 m-dn" md={2}>
                            <ul>
                                <li>
                                    <Link to={"/landowner"}>
                                        Landowners
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/buyer"}>
                                        Buyer
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/customer"}>
                                    Customer
                                    </Link>
                                </li>
                            </ul>
                        </Col>
                        <Col className="listing p-0" md={2}>
                            <ul>
                                <li>
                                    <Link to={"/career"}>
                                        Career
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/news-events"}>
                                        News and Events
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/contact-us"}>
                                        Contact Us
                                    </Link>
                                </li>
                            </ul>
                        </Col>
                    </Row>

                    <Row className="copyright">
                        <Col className="d-flex justify-content-between">
                            <p>© {moment(Date()).format("YYYY")} Lanco Developments. All rights reserved</p>
                            {/*<p>Designed & Developed by Brandmyth Digital</p>*/}
                            <Link to={"https://dcastalia.com/"} target="_blank">
                                Designed & Developed by Dcastalia
                            </Link>

                        </Col>
                    </Row>
                </Container>
            </StyledComponent>
        </>
    );
};

const StyledComponent = styled.section`
   background-color: #1C1C1C;
   position: relative;
   overflow: hidden;
   z-index: 99;
  padding-bottom: 40px;

   .footer__logo {
     a{
       h5{
         font-size: 24px;
         line-height: 32px;
         font-weight: 500;
         color: #F5F5F5;
       }
       p{
         font-size: 14px;
         line-height: 21px;
         font-weight: 500;
         color: #9B9B9B;
         margin-top: 7px;
       }
     }
      

      ul {
         margin-top: 30px;
         display: flex;

         li {
            margin-bottom: 0 !important;

            &:not(:nth-last-of-type(1)) {
               margin-right: 15px;
            }
           svg{
             react{
               transition: all 0.3s ease-in-out;
             }
             path{
               transition: all 0.3s ease-in-out;
             }
           }

            &:hover {
              svg{
                rect{
                  fill: #ffffff;
                  transition: all 0.3s ease-in-out;
                }
                path{
                  fill: #ffffff;
                  transition: all 0.3s ease-in-out;
                }
              }
            }
         }
      }
   }

   ul {
      li {
         &:not(:nth-last-of-type(1)) {
            margin-bottom: 30px;
         }

         a {
            color: #ffffff;
        
            font-weight: 500;
         }
      }
   }
  
  .listing{
    ul {
      li {
        margin-bottom: 18px !important;
        &:last-child{
          margin-bottom: 0;
        }
        a{
          font-size: 14px;
          line-height: 20px;
          font-weight: 300;
          color:#EAEAEA;
        }
      }
    }
    @media(max-width: 767px){
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }

   .copyright {
      padding-top: 20px;
      margin-top: 80px;
      position: relative;
     border-top: 1px solid rgb(254 254 254 / 10%);

      p,
      a {
        font-size: 12px;
        line-height: 20px;
        color: #8B8B8B;
        
       
      }
       
       @media(max-width: 767px){
           .d-flex{
               flex-direction: column;
           }
       }
   }

   @media (min-width: 768px) {
      .social {
         display: none;
      }
   }

   @media (max-width: 767px) {
      .footer__logo {
         min-width: 100%;
         margin-bottom: 30px;

         p {
            margin-top: 40px;
            padding-bottom: 30px;
            border-bottom: 1px solid #fff;
         }

         ul {
            //display: none;
         }
      }

      .col-sm-2 {
         margin: 0 0 40px 0 !important;
         min-width: 33.333%;
         padding: 0 15px !important;
      }

      .social {
         display: flex;

         li {
            margin-right: 20px !important;
         }
      }

      .copyright {
         margin-top: 0;

         .justify-content-between {
            flex-wrap: wrap;
         }
      }
   }

   @media (max-width: 580px) {
      .col-sm-2 {
         min-width: 50%;
         max-width: 50%;
      }

      .m-dn {
         display: none;
      }

      .parallax-logo {
         width: 100%;

         img {
            position: absolute;
            bottom: 0;
            width: 99%;
         }
      }
   }
`;

export default MyComponent;
