import React, {useEffect} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import InnerBanner from "../../components/InnerBanner";
import Zigzag from "../../components/mission/Zigzag";
import Approach from "../../components/story/Approach";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import {fetchMissionData} from "../../api/redux/mission";
import {Helmet, HelmetProvider} from "react-helmet-async";

const MyComponent = () => {

    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'mission-vision-values',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post] : 'yes',
        }

        dispath(fetchMissionData([api_url , api_params]))
    }, [])


    //Refactor Data

    const getData = useSelector((state) => state.mission);

    const banner = getData?.posts?.data;
    const zigzag = getData?.posts?.data?.sections?.find(e => e?.section_data?.slug === 'text-image')?.posts?.list;

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getData?.posts?.data?.page_data?.title ? getData?.posts?.data?.page_data?.title   : 'Lanco Development Ltd'}</title>
                <meta name="description"  content={getData?.posts?.data?.page_data?.meta_desc ? getData?.posts?.data?.page_data?.meta_desc :''}/>
                <meta property="og:title" content={getData?.posts?.data?.page_data?.og_title ? `${getData?.posts?.data?.page_data?.og_title | 'Lanco Development Ltd'}` : ''}/>
                <meta property="og:subtitle" content={getData?.posts?.data?.page_data?.og_title ? `${getData?.posts?.data?.page_data?.og_title | 'Lanco Development Ltd'}` : ''}/>
                <meta property="og:description" content={getData?.posts?.data?.page_data?.og_desc ? getData?.posts?.data?.page_data?.og_desc :''}/>
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:image" content={banner?.page_images?.list?.filter(image => image?.banner === 'on')?.[0]?.full_path}/>
                <meta property="og:type" content={"website"}/>            </Helmet>
            <StyledComponent>
                <InnerBanner
                    title={banner?.page_data?.title}
                    img={banner?.page_images?.list?.filter(image => image?.banner === 'on')?.[0]?.full_path}
                    imgMob={banner?.page_images?.list?.filter(image => image?.mobile === 'on')?.[0]?.full_path}
                />
                <Zigzag data={zigzag}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
