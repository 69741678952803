export const ApiParam = {
    type: 'type',
    value: 'value',
    image : 'image',
    post : 'post',
    file : 'file',
    get_section : 'get_section',
    gallery : 'gallery',
    pageID : 'page_id',
    sections : 'sections',
    category: 'category',
    location: 'location',
};
