import React from 'react';
import styled from 'styled-components';

const Message = (props, {offset}) => {

    return (
        <StyledMessage data-scroll-section offset={offset} className={`title `}
        >
            <div className={'call_wrapper'}>
                <a href={'https://www.facebook.com/messages/t/2576363709048955'} target={'_blank'}>
                    <div id={'click_message'} className="social_bar">
                        <div className="overlay_message"></div>
                        <div className="hover_bar">
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                                <defs>
                                    <clipPath id="clip-path">
                                        <circle id="Ellipse_610" data-name="Ellipse 610" cx="25" cy="25" r="25" transform="translate(1532 770)" fill="none"/>
                                    </clipPath>
                                    <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                        <stop offset="0" stop-color="#76c9ff"/>
                                        <stop offset="1" stop-color="#0299fa"/>
                                    </linearGradient>
                                </defs>
                                <g id="Mask_Group_22155" data-name="Mask Group 22155" transform="translate(-1532 -770)" clip-path="url(#clip-path)">
                                    <circle id="Ellipse_609" data-name="Ellipse 609" cx="25" cy="25" r="25" transform="translate(1532 770)" fill="#09f"/>
                                    <circle id="Ellipse_639" data-name="Ellipse 639" cx="2" cy="2" r="2" transform="translate(1555 793)" fill="url(#linear-gradient)"/>
                                    <g id="Icon" transform="translate(1543.75 782.25)">
                                        <path id="Path_9613" data-name="Path 9613" d="M13.25,1.75c6.646,0,12,4.9,12,10.884s-5.354,10.884-12,10.884a13.053,13.053,0,0,1-4.418-.762l-3.58,1.791a.837.837,0,0,1-1.2-.865l.5-3.555a10.334,10.334,0,0,1-3.3-7.491C1.25,6.653,6.6,1.75,13.25,1.75Zm0,1.674c-5.676,0-10.326,4.1-10.326,9.209A8.724,8.724,0,0,0,5.99,19.178a.835.835,0,0,1,.276.743l-.338,2.413,2.484-1.241a.837.837,0,0,1,.681-.03,11.346,11.346,0,0,0,4.158.779c5.676,0,10.326-4.1,10.326-9.209S18.926,3.424,13.25,3.424Z" transform="translate(0 0)" fill="#fff" fill-rule="evenodd"/>
                                        <path id="Path_9614" data-name="Path 9614" d="M7.442,15.353a.335.335,0,0,1-.487-.445C7.971,13.326,9.472,10.993,10.3,9.7a1.116,1.116,0,0,1,1.558-.325L13.7,10.6a1.117,1.117,0,0,0,1.161.047l3.22-1.789a.335.335,0,0,1,.442.479L15.4,14.015a1.115,1.115,0,0,1-1.482.351L11.567,13.02a1.116,1.116,0,0,0-1.239.088L7.442,15.353Z" transform="translate(0.657 0.821)" fill="#fff" fill-rule="evenodd"/>
                                    </g>
                                </g>
                            </svg>

                        </div>
                    </div>
                </a>
            </div>
        </StyledMessage>

    )
};


const StyledMessage = styled.div`
  position: fixed;
  right: 50px;
  bottom: 110px;
  z-index: 990;


  .overlay_message {
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    position: fixed;
    touch-action: none;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    display: none;
  }

  .message_wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    left: 60px;

    .hover {
      background-color: #F0EDE3;
      height: 50px;
      width: 50px;
      overflow: hidden;
      border-radius: 50% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
      transition: 0.7s all cubic-bezier(0.4, 0, 0, 1);
      cursor: pointer;
      position: relative;

      &:after {
        display: none;
      }

      .hover_bar {
        visibility: visible;
        opacity: 1;
        transition: 0.5s all cubic-bezier(0.4, 0, 0, 1);
        display: flex;
        position: relative;
        margin: auto;

        svg {
          position: absolute;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}




@media (max-width: 767px) {
  right: 15px;
  left: unset;
  bottom: 120px;
}



`;


export default Message;














