import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger'
import {LazyLoadImage} from "react-lazy-load-image-component";

export const ImageParallaxNoLoad = ({src, visible, alt, objectFit, position,height, width, banner, left, margin, right, top, bottom}) => {

    const [imageSrc, setImageSrc] = useState(null);
    const imgRef = useRef(null);

    const DefaultBanner = '/images/static/blurbg.svg';

    const handleImageLoad = () => {
        // When the main image is loaded, update the state to show it smoothly.
        setImageSrc(src);
    };

    useEffect(() => {
        // Initialize the ScrollTrigger animation
        const tl = gsap.timeline({
            scrollTrigger: {
                id: 'parallaxTrigger',

                trigger: imgRef.current,
                start: '10', // Adjust this to control when the animation starts
                end: 'bottom center', // Adjust this to control when the animation ends
                scrub: true, // Enables scrubbing effect
            },
        });

        // Define the parallax animation
        // tl.to(imgRef.current, {
        //     y: '150', // Adjust the parallax effect as needed
        //     ease: 'none',
        // });

        // // You can add more animations here if needed
        //
        // // Ensure that the ScrollTrigger is removed when the component is unmounted
        // return () => {
        //     ScrollTrigger.getById('parallaxTrigger').kill(); // Replace 'parallaxTrigger' with a unique ID for your component
        // };
    }, []);

    return (
        <StyledImg
            className="global-image"
            objectFit={objectFit}
            margin={margin}
            position={position}
            left={left}
            right={right}
            top={top}
            bottom={bottom}
            height={height}
            width={width}
        >
            <div className="image-container"  ref={imgRef}>
                <img  data-speed={0.8} alt={alt || ''} src={src || DefaultBanner} />
            </div>
        </StyledImg>
    );
};

const StyledImg = styled.div`
  position: ${props => props.position || 'absolute'};
  height: ${props => props.height || '100%'};
  width: ${props => props.width || '100%'};
  top: ${props => props.top || 0};
  left: ${props => props.left || 0};
  bottom: ${props => props.bottom || 0};
  right: ${props => props.right || 0};
  margin: ${props => props.margin || 0};

  background-color: #2F374B; /* Set the background color here */

  .image-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: ${(props) => props.objectFit || 'cover'};
      position: absolute;
      top: -10px;
      left: 0;
      right: 0;
      bottom: 0;
      transition: opacity 0.3s ease;
    }
  }
}`;
