import React, { useEffect} from 'react';
import styled from 'styled-components';
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";



const ListWithForm = ({link, img, title}) => {
    return (
        <StyledListWithForm className={'map'}>
           <a href={link} target={"_blank"}>
               <p>{reactHtmlParser(title)}</p>
               <Img src={img}/>
               <img className={'location'} src={'/images/static/location.png'} alt="location"/>
           </a>
        </StyledListWithForm>
    )
};

const StyledListWithForm = styled.div`
  background: #F9F9F9;
  position: relative;
  padding-top: calc(500 / 1366 * 100%);
    
    .location{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    a{
        p{
            position: absolute;
            top: 50%;
            left: 50%;
            color: red;
            z-index: 999999;
            transform: translate(-50%, 20px);
        }
    }
  
  @media(max-width: 767px){
    padding-top: calc(400 / 375 * 100%);
  }
`;


export default React.memo(ListWithForm);
